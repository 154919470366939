import React from 'react';

const DataCard = (props) => {
  return (
    <div className="data-card">
      <p className="heading-1">{props.heading}</p>
      <p className="subheading-1">{props.subheading}</p>
      <h2 className="data-text-1">{props.dataValue}</h2>
    </div>
  );
};

export default DataCard;
