import React from 'react';
import moment from 'moment';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from '@react-pdf/renderer';

let roboto_slab_bold = require('../assets/fonts/RobotoSlab-Bold.ttf');
let roboto_slab_regular = require('../assets/fonts/RobotoSlab-Regular.ttf');

Font.register({
  family: 'Roboto bold',
  src: roboto_slab_bold,
});

Font.register({
  family: 'Roboto regular',
  src: roboto_slab_regular,
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 85,
    paddingHorizontal: 35,
  },
  header: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContet: 'center',
    marginBottom: '30px',
  },
  textBold: {
    fontSize: '10',
    fontWeight: '700',
    fontFamily: 'Roboto bold',
  },
  bgGrey: {
    backgroundColor: '#eee',
  },
  th: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '0',
    backgroundColor: '#eee',
    padding: '8px 0',
    paddingLeft: '10px',
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
  },
  title: {
    fontSize: '12',
    fontWeight: '700',
    fontFamily: 'Roboto bold',
  },
  customWIdth: {
    width: '170px',
    maxWidth: '170px',
    flexBasis: '30%',
  },
  customWidth2: {},
  td: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '0',
    textAlign: 'left',
    padding: '8px',
    borderRight: 1,
    borderBottom: 1,
  },
  firstColumnBorder: {
    borderLeft: 1,
  },
  tableFlexWrapper: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContet: 'center',
  },
  txtCenter: {
    textAlign: 'center',
    paddingLeft: 0,
  },
  text: {
    fontFamily: 'Roboto regular',
    fontSize: 10,
  },
  spacing: {
    marginBottom: 20,
  },
  paddingBottom: {
    marginBottom: 50,
  },
  date: {
    marginLeft: 'auto',
    paddingTop: 10,
  },
  image: {
    marginRight: 10,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

// reate Document Component
export const MyDocument = ({data, name}) => {
  // const partners = localStorage.getItem("partners")
  const partners = JSON.parse(localStorage.getItem('pdfData')) ?? data;

  // const isWithinMonth = (date) => {
  //   const firstDayOfMonth = moment(partners?.month_reported).startOf('month');

  //   const lastDayOfMonth = moment(partners?.month_reported).endOf('month');

  //   return firstDayOfMonth <= moment(date) && lastDayOfMonth >= moment(date);
  // };
  // console.log('%c ⚠ data ', 'color:yellow;background:black;padding:5px;', data);

  const formatMoney = (amount) => {
    let price = amount;

    price = (amount / 100).toFixed(2);

    let numberFormat = new Intl.NumberFormat(['en-US'], {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    });

    return numberFormat.format(price);
  };

  var inLifeBreakIndex = [];

  const inLifeBreak = (index) => {
    const total = partners?.inlife_customers.length;

    if (total > 11) {
      if (index === 10) {
        inLifeBreakIndex.push(index);
        return true;
      }
      if ((index - 10) % 22 === 0) {
        inLifeBreakIndex.push(index);
        return true;
      }
    }

    if (total - 1 === index) inLifeBreakIndex.push(index);

    return false;
  };

  // useEffect(() => {
  //   console.log(partners?.inlife_customers.length);
  //   console.log(inLifeBreakIndex);

  //   let breakingPoint = 0;
  //   if (inLifeBreakIndex.length > 1) {
  //     breakingPoint =
  //       inLifeBreakIndex[inLifeBreakIndex.length - 1] -
  //       inLifeBreakIndex[inLifeBreakIndex.length - 2] +
  //       1;

  //     if (breakingPoint > 15) isTrialBreak = true;
  //   } else {
  //     if (inLifeBreakIndex.length === 1) {
  //       breakingPoint = inLifeBreakIndex[0];
  //       if (breakingPoint > 5) isTrialBreak = true;
  //     }
  //   }

  //   console.log(breakingPoint, isTrialBreak);
  // });

  return (
    <Document>
      <Page style={styles.body}>
        {/* {console.log('props', data)} */}
        <View style={[styles.header]}>
          <Image
            style={styles.image}
            src={require('../assets/images/logo-dark.png')}
          />
          <View style={styles.headerTextWrapper}>
            <Text style={[styles.textBold]}>Partner RevenueShare Report</Text>

            <Text style={styles.text}>for {name}</Text>
          </View>
          <Text style={[styles.text, styles.date]}>
            {moment(partners?.date_generated)
              .startOf('month')
              .format('MMMM D YYYY')}
          </Text>
        </View>

        <View style={styles.description}>
          <Text style={[styles.text, styles.spacing]}>
            Thank you for working with us as a Billsby Partner. This revenue
            share report confirms the amount of revenue share owing for the
            month of {partners?.month_reported}, and includes a list of all
            customers who are currently paying for Billsby service or within
            their free trial, and who were referred by you.
          </Text>
          <Text style={[styles.text, styles.spacing]}>
            In addition to these customers, you have{' '}
            {partners?.customer_signups} customers who have signed up, but have
            not yet reached the stage where an on-file payment card has been
            provided to Billsby. For confidentiality reasons, these records are
            not included in the report
          </Text>
          <Text style={[styles.text, styles.spacing]}>
            Your payment will be processed by your assigned Partnerships
            Associate in the usual way
          </Text>
        </View>

        <View style={styles.spacing}>
          <Text style={[styles.title, styles.spacing]}>Summary</Text>
          <View style={[styles.tableFlexWrapper]}>
            <Text
              style={[
                styles.textBold,
                styles.th,
                styles.firstColumnBorder,
                styles.txtCenter,
              ]}
            >
              Invoiced by Billsby
            </Text>
            <Text style={[styles.textBold, styles.th, styles.txtCenter]}>
              Your Revenue Share
            </Text>
            <Text style={[styles.textBold, styles.th, styles.txtCenter]}>
              Revenue Share Owed
            </Text>
          </View>
          <View style={styles.tableFlexWrapper}>
            <Text
              style={[
                styles.td,
                styles.firstColumnBorder,
                styles.txtCenter,
                styles.text,
              ]}
            >
              {partners?.invoiced_by_billsby_formatted}
            </Text>
            <Text style={[styles.td, styles.txtCenter, styles.text]}>
              {partners?.revenue_share_percentage}
            </Text>
            <Text style={[styles.td, styles.txtCenter, styles.text]}>
              {partners?.revenue_share_owed_formatted}
            </Text>
          </View>
        </View>

        <View style={[styles.spacing]}>
          <Text style={[styles.text, styles.title, styles.spacing]}>
            In-Life Customers
          </Text>
          <View style={[styles.tableFlexWrapper]}>
            <Text
              style={[
                styles.textBold,
                styles.th,
                styles.firstColumnBorder,
                styles.customWIdth,
              ]}
            >
              {' '}
              Name
            </Text>
            <Text style={[styles.textBold, styles.th]}>Invoice Date</Text>
            <Text style={[styles.textBold, styles.th]}>Invoice Amount</Text>
            <Text style={[styles.textBold, styles.th]}>Revenue Share Owed</Text>
          </View>
          {partners?.inlife_customers.map((customer, i) => {
            let isEven = i % 2 === 0 ? null : 'bgGrey';
            return (
              <View
                break={inLifeBreak(i)}
                // break={(i + 1) % 23 === 0}
                key={i}
                style={[styles.tableFlexWrapper, styles[isEven]]}
              >
                <Text
                  style={[
                    styles.text,
                    styles.td,
                    styles.firstColumnBorder,
                    styles.customWIdth,
                  ]}
                >
                  {customer?.name}
                </Text>
                <Text style={[styles.text, styles.td]}>
                  {customer?.invoice_date}
                </Text>
                <Text style={[styles.text, styles.td]}>
                  {customer?.invoice_date
                    ? formatMoney(customer?.invoice_amount)
                    : '-'}
                </Text>
                <Text style={[styles.text, styles.td]}>
                  {customer?.invoice_date
                    ? formatMoney(customer?.revenue_share_owed)
                    : '-'}
                </Text>
              </View>
            );
          })}
        </View>

        <View break style={[styles.spacing, styles.paddingBottom]}>
          <Text style={[styles.text, styles.title, styles.spacing]}>
            Trial Customers
          </Text>
          <View style={[styles.tableFlexWrapper]}>
            <Text
              style={[styles.textBold, styles.th, styles.firstColumnBorder]}
            >
              {' '}
              Name
            </Text>
            <Text style={[styles.textBold, styles.th]}> Created On</Text>
          </View>

          {partners?.trial_customers.map((trial_customer, i) => {
            let isEven = i % 2 === 0 ? null : 'bgGrey';
            // let counter = i + 1;
            return (
              <View
                key={i}
                break={(i + 1) % 22 === 0}
                // break={
                //   (counter < 23 && counter % 22 === 0) ||
                //   (counter > 23 && counter % 23 === 0)
                // }
                style={[styles.tableFlexWrapper, styles[isEven]]}
              >
                <Text
                  style={[styles.text, styles.td, styles.firstColumnBorder]}
                >
                  {trial_customer?.name}
                </Text>
                <Text style={[styles.text, styles.td]}>
                  {trial_customer?.created_on}
                </Text>
              </View>
            );
          })}
        </View>

        <Text
          style={styles.pageNumber}
          render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};
export const PartnerRevenuePDFPreview = () => {
  return (
    <PDFViewer>
      <MyDocument />
    </PDFViewer>
  );
};
