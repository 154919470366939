import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import {useHistory} from 'react-router-dom';
import ScoreCard from '../components/Scorecard';
import axios from 'axios';
import {Evaluation} from '../helpers/Urls';
import {calculateScore} from '../helpers/Functions';
import Auth from '../helpers/Auth';

const CustomerEdit = () => {
  var history = useHistory();
  const [customerInfo, setCustomerInfo] = useState({
    score: null,
    company: null,
    email: null,
    scorecard: {
      mrr: null,
      size: null,
      industry: null,
      migration: null,
      skill: null,
      xFactor: 0,
    },
  });

  const goBack = () => {
    history.goBack();
  };
  useEffect(() => {
    var id = history.location.search;
    axios
      .get(Evaluation.fetchCustomerInfo + id, Auth.getAuthConfig())
      .then(({data}) => setCustomerInfo(data))
      .catch((err) => console.log(err));
    //eslint-disable-next-line
  }, []);

  const onUpdateData = (data) => {
    var calculatedScore = calculateScore(data);
    data.score = calculatedScore;
    setCustomerInfo(data);
  };

  const onSaveData = () => {
    axios
      .post(Evaluation.updateCustomerInfo, customerInfo)
      .then(({data}) => console.log(data))
      .catch((err) => console.log(err));
    console.log('Data has been saved');
  };

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="back-link">
            <span onClick={goBack}>{'< Back to report'}</span>
          </div>

          <div className="customer-short-info">
            <div className="customer-score">{customerInfo.score}</div>
            <div className="customer-info">
              <div className="customer-name">{customerInfo.company}</div>
              <div className="customer-email">{customerInfo.email}</div>
            </div>
          </div>

          {customerInfo.name !== null && (
            <ScoreCard
              data={customerInfo}
              usage="edit"
              onUpdateData={onUpdateData}
              onSaveData={onSaveData}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerEdit;
