import axios from 'axios';
import {AuthUrl} from './Urls';
class Auth {
  constructor() {
    if (typeof window !== `undefined`) {
      const token = localStorage.getItem('billsbyToken');
      const email = localStorage.getItem('email');

      if (token && email) {
        localStorage.setItem('authenticated', JSON.stringify(true));
      }
    }
  }

  login(token, email, callback) {
    if (typeof window !== `undefined`) {
      localStorage.setItem('visited', JSON.stringify(false));
      localStorage.setItem('billsbyToken', token);
      localStorage.setItem('email', email);
      localStorage.setItem('authenticated', JSON.stringify(true));
    }

    callback();
  }

  getAuthConfig() {
    if (typeof window !== `undefined`) {
      if (localStorage.getItem('authenticated')) {
        return {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('billsbyToken')}`,
          },
        };
      }
    }
  }

  get(attribute) {
    if (typeof window !== `undefined`) {
      return localStorage.getItem(attribute);
    }
  }

  logout() {
    if (typeof window !== `undefined`) {
      localStorage.clear();
      window.location.replace('/#/login');
      window.location.reload();
    }
  }

  isAuthenticated() {
    if (typeof window !== `undefined`) {
      return localStorage.getItem('authenticated');
    }
  }

  checkAuthCredentials() {
    return axios
      .get(AuthUrl.verify, {
        headers: {
          Authorization: 'Bearer ' + localStorage.billsbyToken,
        },
      })
      .then((success) => true)
      .catch((err) => false);
  }
}

export default new Auth();
