import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ReportTableSimple from '../components/ReportTableSimple';
import Auth from '../helpers/Auth';
import {Invoice} from '../helpers/Urls';

const Breakdown = (props) => {
  var history = useHistory();
  const {
    match: {params},
  } = props;

  const [currencyData, setCurrencyData] = useState([]);

  const requestData = () => {
    axios
      .post(
        Invoice.dateBreakdown,
        {
          date: params.date,
        },
        Auth.getAuthConfig()
      )
      .then((result) => {
        setCurrencyData(result.data);
      })
      .catch((err) => console.log(err));
  };

  const tableHeaders = [
    {title: 'Currency Code'},
    {title: 'Total Paid Invoices'},
    {title: 'Invoice Value'},
    {title: 'Invoice Value', subtitle: '(excl. free trial)'},
    {title: 'Exchange Rate'},
  ];

  useEffect(() => {
    requestData();
    //eslint-disable-next-line
  }, []);

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="invoice-value body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="breakdown-close-tab">
            {/* <Link to="/invoice-value">{'< Back to report'}</Link> */}
            <span onClick={goBack}>{'< Back to report'}</span>
          </div>

          <div className="breakdown-main">
            <div className="breakdown-title">
              Currency breakdown on {moment(params.date).format('MMMM D YYYY')}
            </div>
            {currencyData.length !== 0 ? (
              <ReportTableSimple
                tableHeaders={tableHeaders}
                statistics={currencyData}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Breakdown;
