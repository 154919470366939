class Global {
  nullChecker = value => {
    if (value === null) {
      return 0;
    }

    if (value % 1 === 0) {
      return value;
    }

    if (value % 1 !== 0) {
      var multiplier = Math.pow(10, 1 || 0);
      return Math.round(value * multiplier) / multiplier;
    }
  }

  marketingNullChecker = value => {
    if (value === null) {
      return 0;
    } else {
      return value
    }
  }
}

export default new Global()