import React from 'react';
import NumberFormat from 'react-number-format';
import global from '../helpers/Global';

const ReportTableSimple = ({tableHeaders, statistics}) => {
  return (
    <div>
      <div className={`section-table cb-chart`}>
        <div className="tr">
          {tableHeaders.map((header, index) => (
            <div className="th" key={index}>
              {header.title}
              <br />
              <small>{header.subtitle}</small>
            </div>
          ))}
        </div>
        {statistics.map((stat) => (
          <div className="tr" key={stat.id}>
            <div className="td">{stat.currency_iso3}</div>
            <div className="td">
              <NumberFormat
                value={global.marketingNullChecker(stat.total_paid_invoices)}
                displayType={'text'}
                thousandSeparator={true}
              />
            </div>
            <div className="td">
              <NumberFormat
                value={global.marketingNullChecker(stat.invoice_value)}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </div>
            <div className="td">
              <NumberFormat
                value={global.marketingNullChecker(
                  stat.invoice_value_no_free_trial
                )}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </div>
            <div className="td">
              <NumberFormat
                value={global.marketingNullChecker(stat.exchange_rate)}
                displayType={'text'}
                thousandSeparator={true}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportTableSimple;
