import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ScoreCard from '../components/Scorecard';

import {Evaluation} from '../helpers/Urls';
import {calculateScore} from '../helpers/Functions';
import Auth from '../helpers/Auth';

const CompanyEvaluation = () => {
  var history = useHistory();

  const [listIndex, setListIndex] = useState(0);
  const [dataList, setDataList] = useState([
    {
      score: null,
      company: null,
      email: null,
      scorecard: {
        mrr: null,
        size: null,
        industry: null,
        migration: null,
        skill: null,
        xFactor: 0,
      },
    },
  ]);

  const [dataToEvaluate, setDataToEvaluate] = useState(
    () => dataList[listIndex]
  );

  const onUpdateData = (data) => {
    var calculatedScore = calculateScore(data);
    data.score = calculatedScore;
    setDataToEvaluate(data);
  };

  const returnToEvaluationPage = () => {
    history.push('/evaluation-customers');
  };

  const clickedBtn = async (type) => {
    setListIndex((prevIndex) => {
      var newIndex = prevIndex + 1;
      console.log(newIndex);
      if (type === 'Save and Next') {
        async function saveAndNext(index) {
          var savedData = await saveToDb();
          console.log('Save and Next:', savedData);
          setDataToEvaluate(() => dataList[newIndex]);
        }
        saveAndNext(newIndex);
      } else if (type === 'Save and Quit') {
        async function saveAndQuit(index) {
          var savedData = await saveToDb();
          console.log('Save and Quit:', savedData);
          returnToEvaluationPage();
        }
        saveAndQuit(newIndex);
      } else if (type === 'Skip and Next') {
        console.log('Skip and Next:', dataToEvaluate);
        setDataToEvaluate(() => dataList[newIndex]);
      } else if (type === 'Skip and Quit') {
        console.log('Skip and Quit:', dataToEvaluate);
        returnToEvaluationPage();
      } else {
        console.log('Do nothing');
      }
      return newIndex;
    });
  };

  const saveToDb = async () => {
    var dataToSave = dataToEvaluate;
    if (dataToSave.scorecard.mrr === null) dataToSave.scorecard.mrr = 'Unknown';
    if (dataToSave.scorecard.size === null)
      dataToSave.scorecard.size = 'Unknown';
    if (dataToSave.scorecard.industry === null)
      dataToSave.scorecard.industry = 'Unknown';
    if (dataToSave.scorecard.skill === null)
      dataToSave.scorecard.skill = 'Unknown';
    if (dataToSave.scorecard.migration === null)
      dataToSave.scorecard.migration = 'Unknown';
    if (dataToSave.scorecard.xFactor === null) dataToSave.scorecard.xFactor = 0;

    return await axios
      .post(Evaluation.updateCustomerInfo, dataToSave)
      .then(({data}) => {
        console.log(data);
        return dataToSave;
      })
      .catch((err) => {
        console.log(err);
        return dataToSave;
      });
  };

  useEffect(() => {
    axios.get(Evaluation.getUnscored, Auth.getAuthConfig()).then(({data}) => {
      setDataList(data);
      setDataToEvaluate(data[0]);
    });
  }, []);

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="top-bar">
            <div className="evaluation-title">Company Evaluation</div>
            <div className="evaluation-btn-group">
              {dataToEvaluate.company !== null && (
                <>
                  <button onClick={() => clickedBtn('Save and Next')}>
                    Save and Next
                  </button>
                  <button onClick={() => clickedBtn('Save and Quit')}>
                    Save and Quit
                  </button>
                  <button onClick={() => clickedBtn('Skip and Next')}>
                    Skip and Next
                  </button>
                  <button onClick={() => clickedBtn('Skip and Quit')}>
                    Skip and Quit
                  </button>
                </>
              )}
            </div>
          </div>

          <div>
            <div className="evaluation-company-info">
              <div className="company-name">{dataToEvaluate.company}</div>
              <div className="company-email">{dataToEvaluate.email}</div>
            </div>
            <ScoreCard
              data={dataToEvaluate}
              usage={'save'}
              onUpdateData={onUpdateData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyEvaluation;
