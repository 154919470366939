import React, { useState } from 'react';

// helpers
import classnames from 'classnames'
import axios from 'axios'

// components
import Header from '../components/Header';
import Footer from '../components/Footer';

const Login = () => {
  const [email, setEmail] = useState('')
  const [logInState, setLogInState] = useState('Login')
  const [emailMessage, setEmailMessage] = useState({
    error: false,
    message: '',
    submitted: false
  })

  // methods
  const validEmailDomains = [
    'airfinetworks.com',
    'billsby.com',
    'fullstack.ph'
  ]

  const onEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const onEmailSubmit = (e) => {
    e.preventDefault()

    setLogInState('Loading...')

    let strippedEmail = email.split('@')
    strippedEmail = strippedEmail[1]

    if (validEmailDomains.includes(strippedEmail)) {
      axios.post('https://billsby-mailgun.onrender.com/', {
        to: email
      }).then(({ data }) => {
        console.log(data)
        setEmailMessage({
          error: false,
          message: 'Check your email for your login link.',
          submitted: true
        })
      })
    } else {
      setEmailMessage({
        error: true,
        message: 'Sorry ' + email + ' cant login here.',
        submitted: true
      })
    }
  }

  return (
    <>
      <Header />
      <main className="login">
        <div className="section-hero">
          <div className="container">
            <h1 className="section-heading">The insights you need to see how Billsby is performing, all in one place.</h1>
            <p className="section-text">We're constantly working to add new insights and intelligence so you can understand how Billsby is performing and add them to our reliable reporting system.</p>

            <p className="section-text">For updates, or to request new reports and features, check out the #businessinsights channel on Slack, which is also the place to go for help and support.</p>
          </div>
        </div>

        <div className="section-form">
          <form
            className={classnames('', {
              'form-error': emailMessage.error,
              'form-check': !emailMessage.error,
              'form-hide': !emailMessage.submitted
            })}
            onSubmit={onEmailSubmit}>
            <div className="container">
              <input type="email" className="input-field" onChange={onEmailChange} placeholder="Enter your work email address" />
              <button type="submit" className="input-submit input-login">{logInState}</button>
              <button type="submit" className={classnames('input-submit', {
                'input-error': emailMessage.error,
                'input-check': !emailMessage.error,
                'hide-toast': !emailMessage.submitted
              })}>
                {emailMessage.message}
              </button>
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Login;