import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ReportTable from '../components/ReportTable';
import LogsTable from '../components/LogsTable';
import TopBar from '../components/TopBar';
import {CSVLink} from 'react-csv';
import moment from 'moment';

import auth from '../helpers/Auth';
import {
  compareAge,
  compareMrrLvl,
  compareScore,
  compareSize,
  compareStageLvl,
  compareIndustry,
} from '../helpers/Sort';

import {Evaluation} from '../helpers/Urls';
import CheckEmailAccess from '../helpers/CheckEmailAccess';

import {Context} from '../contexts/Context';
import Auth from '../helpers/Auth';

const EvaluationCustomers = () => {
  const {
    newTrialCustomers,
    setNewTrialCustomers,
    customerList,
    setCustomerList,
  } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);

  const [tableHeaders, setTableHeaders] = useState([
    {title: 'Score'},
    {title: 'Company'},
    {title: 'Industry'},
    {title: 'Age'},
    {title: 'Stage'},
  ]);

  const [csvData, setCsvData] = useState([]);

  const resetTableHeaders = () => {
    if (tableHeaders[4].title !== 'Stage')
      setTableHeaders([
        {title: 'Score'},
        {title: 'Company'},
        {title: 'Industry'},
        {title: 'Age'},
        {title: 'Stage'},
      ]);
  };

  const changeCategory = (category) => {
    setCustomerList((prevList) => {
      let _prevList = prevList.map((prev) => prev);
      if (category === 'Score') {
        resetTableHeaders();
        _prevList.sort(compareScore);
      }
      if (category === 'Stage') {
        resetTableHeaders();
        _prevList.sort(compareStageLvl);
      }
      if (category === 'Age') {
        console.log('sort age');
        resetTableHeaders();
        _prevList.sort(compareAge);
      }
      if (category === 'Industry') {
        resetTableHeaders();
        _prevList.sort(compareIndustry);
      }
      if (category === 'Size') {
        setTableHeaders([
          {title: 'Score'},
          {title: 'Company'},
          {title: 'Industry'},
          {title: 'Age'},
          {title: 'Size'},
        ]);
        _prevList.sort(compareSize);
      }
      if (category === 'MRR') {
        setTableHeaders([
          {title: 'Score'},
          {title: 'Company'},
          {title: 'Industry'},
          {title: 'Age'},
          {title: 'MRR'},
        ]);
        _prevList.sort(compareMrrLvl);
      }
      return _prevList;
    });
  };

  const topBarCategories = ['Score', 'Stage', 'Age', 'Industry', 'Size', 'MRR'];

  useEffect(() => {
    setIsLoading(true);
    // get unscored customers
    axios
      .get(Evaluation.getUnscoredTotal, Auth.getAuthConfig())
      .then(({data}) => {
        if (newTrialCustomers !== data.total_unscoreds)
          setNewTrialCustomers(data.total_unscored);
      })
      .catch((err) => console.log(err));

    // get scored customers
    axios
      .get(Evaluation.getScored, Auth.getAuthConfig())
      .then(({data}) => {
        if (customerList.length !== data.length)
          setCustomerList(data.sort(compareScore));

        setIsLoading(false);
      })
      .catch((err) => console.log(err));

    fetchCSVReport(
      moment().subtract(1, 'days').format('MMM DD YYYY'),
      'evaluation'
    );

    //eslint-disable-next-line
  }, []);

  const fetchCSVReport = (date, type) =>
    axios
      .get(Evaluation.exportAsCsv, {
        params: {
          date: moment(date).format('YYYY-MM-DD'),
          type,
        },
      })
      .then(({data}) => setCsvData(data))
      .catch((error) =>
        console.log(
          '%c ⚠ error ',
          'color:yellow;background:black;padding:5px;',
          error.response
        )
      );

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="body-holder">
        <Sidebar />
        <div className="section-main">
          <TopBar
            categories={topBarCategories}
            callbackCategory={changeCategory}
          >
            <div className="wrapper">
              {!isLoading && (
                <button className="export">
                  <CSVLink
                    data={csvData}
                    filename={` Evaluation ${moment()
                      .subtract(1, 'days')
                      .format('MMM DD YYYY')}.csv`}
                  >
                    Export as .CSV
                  </CSVLink>
                </button>
              )}
              <div className="evaluate-trial-btn">
                {CheckEmailAccess.isScoreCardAccessible(auth.get('email')) && (
                  <>
                    <Link
                      to="/evaluation-customers/company-evaluation"
                      className="link-btn"
                    >
                      Evaluate {newTrialCustomers} new trial customers
                    </Link>
                  </>
                )}
              </div>
            </div>
          </TopBar>

          {isLoading ? (
            <div className="spinner-container">
              <DotLoader color={'#243f5c'} size={100} />
            </div>
          ) : (
            <>
              <ReportTable
                statistics={customerList}
                tableHeaders={tableHeaders}
                chartType="evaluationCustomers"
              />
              <LogsTable mode="evaluationCustomers" />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EvaluationCustomers;
