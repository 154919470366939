import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import global from '../helpers/Global';
import Pagination from 'react-js-pagination';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {MyDocument} from './PartnerRevenuePDF';

import {
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';

const ReportTable = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [range, setRange] = useState(0);
  const [items, setItems] = useState([]);
  const [isReportReady, setIsReportReady] = useState(false);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var data = {
      activePage: pageNumber,
      table: type,
    };
    localStorage.setItem('table-history', JSON.stringify(data));
  };

  useEffect(() => {
    setRange(3);
    if (type === 'evaluationCustomers') setItemsPerPage(10);
    else setItemsPerPage(10);

    if (
      type === 'invoiceValue' ||
      type === 'inLifeCustomers' ||
      'evaluationCustomers'
    ) {
      var tableHistory = JSON.parse(localStorage.getItem('table-history'));
      if (tableHistory) {
        if (tableHistory.table !== type) {
          var data = {
            activePage: 1,
            table: type,
          };
          localStorage.setItem('table-history', JSON.stringify(data));
        }
        if (type === tableHistory.table) setActivePage(tableHistory.activePage);
      } else setActivePage(1);
    }
    setItems(props.statistics);

    if (type === 'partnerReport') {
      if (props.statistics.length > 0)
        if (props.statistics[0].pdf_data && localStorage.getItem('pdfData'))
          setIsReportReady(true);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (type === 'invoiceValue') {
      var th = JSON.parse(localStorage.getItem('table-history'));

      setActivePage((page) => {
        var returnPage;
        if (th) returnPage = th.activePage;
        else returnPage = 1;
        return returnPage;
      });
    }

    setItems(props.statistics);
    //eslint-disable-next-line
  }, [props.statistics]);

  const copyArr = items;

  const slicedItems = copyArr.slice(
    activePage * itemsPerPage - itemsPerPage,
    activePage * itemsPerPage
  );

  const type = props.chartType;

  const defineStage = (stage) => {
    if (stage === 1) return 'Signed up';
    if (stage === 2) return 'Created first plan';
    if (stage === 3) return 'Pre-live';
    if (stage === 4) return 'Free trial';
    if (stage === 5) return 'Paying customer';
    else return 'UNDEFINED LEVEL';
  };

  const DeleteComponent = ({index, data, onDeleteQueue}) => {
    const [clicked, setClicked] = useState(false);
    return (
      <>
        {!clicked ? (
          <div className="cb-link" onClick={() => setClicked(true)}>
            Delete
          </div>
        ) : (
          <>
            Are you sure?&nbsp;
            <div className="cb-link" onClick={() => onDeleteQueue(index, data)}>
              Yes
            </div>
          </>
        )}
      </>
    );
  };

  const tableheaderClass = () => {
    var tableClass = '';

    switch (type) {
      case 'currencyBreakdown':
        tableClass = 'cb-chart';
        break;
      case 'invoiceValue':
        tableClass = 'iv-chart';
        break;
      case 'evaluationCustomers':
        tableClass = 'ec-chart';
        break;
      case 'marketingConversion':
        tableClass = 'mc-chart';
        break;
      case 'inLifeCustomers':
        tableClass = 'ilc-chart';
        break;
      case 'inLifeCustomerView':
        tableClass = 'ilcv-chart';
        break;

      case 'subscriptionClients':
        tableClass = 'msc-lists';
        break;
      case 'clientQueue':
        tableClass = 'msc-queue-lists';
        break;
      case 'clientHistory':
        tableClass = 'msc-history-lists';
        break;

      case 'migratedCustomerClientHistory':
        tableClass = 'msc-history-lists';
        break;
      case 'batchReport':
        tableClass = 'batch-report';
        break;
      case 'csvReport':
        tableClass = 'csv-report';
        break;

      case 'customerClients':
        tableClass = 'mcc-lists';
        break;
      case 'customerClientQueue':
        tableClass = 'mcc-queue-lists';
        break;
      case 'csvReportMigratedCustomer':
        tableClass = 'csv-report-migrated-customers';
        break;
      case 'migratedCustomerBatchReport':
        tableClass = 'mc-batch-report';
        break;
      case 'partnerReport':
        tableClass = 'partner-report';
        break;
      case 'mrrReport':
        tableClass = 'mrr-report';
        break;
      default:
        tableClass = '';
    }

    return tableClass;
  };

  const numberWithDollarCommas = (x) => {
    if (x === null || x === undefined) return 'NA';
    var number = x?.toFixed(2);
    return `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  const numberWithCommas = (x) => {
    if (x === null || x === undefined) return 'NA';
    var number = x.toFixed(2);
    return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  return (
    <>
      <div className={`section-table ${tableheaderClass()}`}>
        <div className="tr">
          {props.tableHeaders.map((header, index) => (
            <div className="th" key={index}>
              {header.title}
              {header.subtitle ? (
                <>
                  <br />
                  <small>{header.subtitle}</small>
                </>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>

        {items.length === 0 && (
          <div className="tr no-data">
            <div>No data available</div>
          </div>
        )}

        {type === 'invoiceValue' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  {' '}
                  {moment(stat.date).format('MMMM D YYYY')}{' '}
                </div>
                <div className="td">
                  <NumberFormat
                    value={global.marketingNullChecker(stat.totalPaidInvoices)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                </div>
                <div className="td">
                  <NumberFormat
                    value={global.marketingNullChecker(stat.totalInvoiceAmount)}
                    displayType={'text'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix="$"
                  />
                </div>
                <div className="td">
                  <NumberFormat
                    value={global.marketingNullChecker(stat.revenueShare)}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="$"
                  />
                </div>

                <div className="td">
                  {stat.totalPaidInvoices > 0 && (
                    <Link
                      className="cb-link"
                      to={`/invoice-value/currency-breakdown/${moment(
                        stat.date
                      ).format('YYYY-MM-DD')}`}
                    >
                      Currency breakdown
                    </Link>
                  )}
                </div>
              </div>
            );
          })}

        {type === 'evaluationCustomers' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{stat.score}</div>
                <div className="td">
                  <Link
                    className="cb-link"
                    to={`/evaluation-customers/customer/${stat.name}?id=${stat.company_id}`}
                  >
                    {stat.name}
                  </Link>
                </div>
                <div className="td">
                  {stat.industry === 'null' ? 'Not specified' : stat.industry}
                </div>
                <div className="td">{stat.age}</div>
                <div className="td">
                  {props.tableHeaders[4].title === 'Stage'
                    ? defineStage(stat.stage_level)
                    : '' || props.tableHeaders[4].title === 'Size'
                    ? stat.size
                    : '' || props.tableHeaders[4].title === 'MRR'
                    ? stat.mrr
                    : ''}
                </div>
              </div>
            );
          })}

        {type === 'inLifeCustomers' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  {numberWithDollarCommas(stat.mrr_converted)}
                </div>
                <div className="td">
                  {stat.mrr_converted !== null ? (
                    <Link
                      className="cb-link"
                      to={`/customer/${stat.name}?id=${stat.company_id}`}
                    >
                      {stat.name}
                    </Link>
                  ) : (
                    stat.name
                  )}
                </div>
                <div className="td">{stat.age}</div>
                <div className="td">{defineStage(stat.stage_level)}</div>
              </div>
            );
          })}

        {type === 'inLifeCustomerView' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  {' '}
                  {moment(stat.date).format('MMMM D YYYY')}{' '}
                </div>
                <div className="td">
                  <NumberFormat
                    value={stat.mrrValueInUSD}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="$"
                  />
                </div>
              </div>
            );
          })}

        {type === 'customerSignups' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  {' '}
                  {moment(stat.date).format('MMMM D YYYY')}{' '}
                </div>
                <div className="td"> {global.nullChecker(stat.signed_up)} </div>
                <div className="td">
                  {' '}
                  {global.nullChecker(stat.first_plan)}{' '}
                </div>
                <div className="td"> {global.nullChecker(stat.pre_live)}</div>
                <div className="td">
                  {' '}
                  {global.nullChecker(stat.card_on_file)}{' '}
                </div>
                <div className="td">
                  {' '}
                  {global.nullChecker(stat.paying_customers)}
                </div>
              </div>
            );
          })}

        {type === 'marketingConversion' &&
          slicedItems.map((stat, i) => {
            var rate = stat.conversion_rate * 100;

            return (
              <div className="tr" key={i}>
                <div className="td">
                  {' '}
                  {moment(stat.date).format('MMMM D YYYY')}{' '}
                </div>
                <div className="td">
                  <NumberFormat
                    value={global.marketingNullChecker(stat.uniques)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                </div>
                <div className="td">
                  <NumberFormat
                    value={global.marketingNullChecker(stat.sign_ups)}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                </div>
                <div className="td">
                  <NumberFormat
                    value={global.marketingNullChecker(rate)}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix="%"
                  />

                  {/* <NumberFormat value={rate} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true} suffix="%" /> */}
                  {/* {(stat.conversion_rate * 100)} */}
                </div>
                <div className="td">
                  <NumberFormat
                    value={global.marketingNullChecker(stat.ad_budget)}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="$"
                  />
                </div>
                <div className="td">
                  <NumberFormat
                    value={global.marketingNullChecker(
                      stat.cost_per_conversion
                    )}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="$"
                  />
                </div>
              </div>
            );
          })}

        {type === 'subscriptionClients' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  <Link
                    className="cb-link"
                    to={`/tools/migrate-subscriptions/${stat.client_name}`}
                    onClick={() => props.onClientClick(stat)}
                  >
                    {stat.client_name}
                  </Link>
                </div>
                <div className="td">
                  <NumberFormat
                    value={stat.migrated}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix=" subscriptions"
                  />
                </div>
                <div className="td">
                  <NumberFormat
                    value={stat.left_to_migrate}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix=" subscriptions"
                  />
                </div>
                <div className="td">
                  <NumberFormat
                    value={stat.failed}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix=" subscriptions"
                  />
                </div>
              </div>
            );
          })}

        {type === 'clientQueue' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{stat.customer_id}</div>
                <div className="td">{stat.customer_email}</div>
                <div className="td">{stat.cycle_id}</div>
                <div className="td">{stat.scheduled_formatted}</div>
                <div className="td">
                  <DeleteComponent
                    index={i}
                    data={stat}
                    onDeleteQueue={props.onDeleteQueue}
                  />
                </div>
              </div>
            );
          })}

        {type === 'batchReport' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{stat.customer_id}</div>
                <div className="td">{stat.customer_email}</div>
                <div className="td">{stat.new_cycle_id}</div>
                {stat.failure_reasons ? (
                  <div className="td">{stat.failure_reasons}</div>
                ) : (
                  <div className="td">{stat.subscription_id}</div>
                )}
              </div>
            );
          })}

        {type === 'csvReport' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{stat.customer_id}</div>
                <div className="td">{stat.customer_email}</div>
                <div className="td">{stat.new_cycle_id}</div>
                {stat.scheduled ? (
                  <div className="td">{stat.scheduled}</div>
                ) : (
                  <div className="td">{stat.rejection_reasons}</div>
                )}
              </div>
            );
          })}

        {type === 'clientHistory' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{stat.scheduled_formatted}</div>
                <div className="td">{stat.succeeded}</div>
                <div className="td">{stat.error}</div>
                <div className="td">
                  <span
                    className="cb-link"
                    onClick={() => props.onClickHistory(stat)}
                  >
                    View Report
                  </span>
                </div>
              </div>
            );
          })}

        {type === 'customerClients' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  <Link
                    className="cb-link"
                    to={`/tools/migrate-customers/${stat.client_name}`}
                    onClick={() => props.onClientClick(stat)}
                  >
                    {stat.client_name}
                  </Link>
                </div>
                <div className="td">
                  <NumberFormat
                    value={stat.migrated}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix=" customers"
                  />
                </div>
                <div className="td">
                  <NumberFormat
                    value={stat.failed}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix=" customers"
                  />
                </div>
              </div>
            );
          })}

        {type === 'customerClientQueue' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{stat.customer_name}</div>
                <div className="td">{stat.customer_email}</div>
                <div className="td">{stat.scheduled_formatted}</div>
                <div className="td">
                  <DeleteComponent
                    index={i}
                    data={stat}
                    onDeleteQueue={props.onDeleteQueue}
                  />
                </div>
              </div>
            );
          })}

        {type === 'csvReportMigratedCustomer' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  {stat.customer_name
                    ? stat.customer_name
                    : `${stat.customer_first_name} ${stat.customer_last_name}`}
                </div>
                <div className="td">{stat.customer_email}</div>
                {stat.scheduled ? (
                  <div className="td">{stat.scheduled_formatted}</div>
                ) : (
                  <div className="td">{stat.rejection_reasons}</div>
                )}
              </div>
            );
          })}

        {type === 'migratedCustomerClientHistory' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{stat.scheduled_formatted}</div>
                <div className="td">{stat.succeeded}</div>
                <div className="td">{stat.error}</div>
                <div className="td">
                  <span
                    className="cb-link"
                    onClick={() => props.onClickHistory(stat)}
                  >
                    View Report
                  </span>
                </div>
              </div>
            );
          })}

        {type === 'migratedCustomerBatchReport' &&
          slicedItems.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  {stat.customer_first_name + ' ' + stat.customer_last_name}{' '}
                </div>
                <div className="td">{stat.customer_email}</div>
                {stat.failure_reasons ? (
                  <div className="td">{stat.failure_reasons}</div>
                ) : (
                  <div className="td">{stat.customer_unique_id}</div>
                )}
              </div>
            );
          })}

        {type === 'partnerReport' &&
          slicedItems.map((props, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">{props?.partner}</div>
                <div className="td">
                  {props?.pdf_data?.revenue_share_owed_formatted}
                </div>
                <div className="td">
                  {/* <button onClick={() => props.downloadCallback(stat.report)}>
                    Download
                  </button>{' '} */}

                  {isReportReady && (
                    <>
                      <PDFDownloadLink
                        document={
                          <MyDocument
                            data={props?.pdf_data}
                            test={props?.partner}
                            name={props?.partner}
                          />
                        }
                        fileName={`${props?.partner} - ${props?.pdf_data.month_reported}.pdf`}
                      >
                        {({blob, url, loading, error}) => {
                          return (
                            <>
                              <button>
                                {loading ? 'Loading document...' : 'Download'}
                              </button>
                              {/* <a target="_blank" href={url}>
                                {' '}
                                preview
                              </a> */}
                            </>
                          );
                        }}
                      </PDFDownloadLink>
                      {/* <PartnerRevenuePDFPreview /> */}
                    </>
                  )}
                </div>
              </div>
            );
          })}

        {type === 'mrrReport' &&
          props?.statistics?.map((stat, i) => {
            return (
              <div className="tr" key={i}>
                <div className="td">
                  <Link
                    className="cb-link"
                    to={`/customer/${stat.name}?id=${stat.id}`}
                  >
                    {stat.name}
                  </Link>
                </div>
                <div className="td">{numberWithDollarCommas(stat.mrr)}</div>
                <div className="td">
                  <div className="change-holder">
                    <FontAwesomeIcon
                      icon={
                        stat.changeStatus === 'positive'
                          ? faChevronUp
                          : faChevronDown
                      }
                      className={stat.changeStatus}
                    />
                    {(stat.prefix ? stat.prefix : '') +
                      numberWithCommas(stat.change) +
                      (stat.suffix ? stat.suffix : '')}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {props.hidePagination ? (
        ''
      ) : (
        <div className="pagination-wrapper">
          <Pagination
            firstPageText={
              <FontAwesomeIcon
                icon={faAngleDoubleLeft}
                className="icon icon-grid"
              />
            }
            lastPageText={
              <FontAwesomeIcon
                icon={faAngleDoubleRight}
                className="icon icon-grid"
              />
            }
            prevPageText={
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="icon icon-grid"
              />
            }
            nextPageText={
              <FontAwesomeIcon
                icon={faChevronRight}
                className="icon icon-grid"
              />
            }
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={items.length}
            pageRangeDisplayed={range}
            onChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default ReportTable;
