import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import ReportTable from '../components/ReportTable';
import {InLife, MRRMovers} from '../helpers/Urls';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import Auth from '../helpers/Auth';

const InLifeCustomerView = () => {
  var history = useHistory();
  const [customerInfo, setCustomerInfo] = useState({});
  const [customerHistory, setCustomerHistory] = useState([]);
  const [moversData, setMoversData] = useState({
    thirty: {
      percent: 'calculating...',
      percent_indicator: 'positive',
      dollar: 'calculating...',
      dollar_indicator: 'positive',
    },
    seven: {
      percent: 'calculating...',
      percent_indicator: 'positive',
      dollar: 'calculating...',
      dollar_indicator: 'positive',
    },
  });

  const tableHeaders = [{title: 'Date'}, {title: 'MRR'}];

  const goBack = () => {
    history.goBack();
  };
  const id = history.location.search;

  const numberWithCommas = (x) => {
    if (x !== null) {
      var number = x.toFixed(2);
      return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    } else return 'NA';
  };

  const fetchData = () => {
    // Fetch customer data
    axios
      .get(InLife.fetchCustomerHistory + id, Auth.getAuthConfig())
      .then(({data}) => {
        setCustomerInfo(data);
        setCustomerHistory(data.history);
      })
      .catch((err) => console.log(err));

    // Fetch movers data
    let _id = id.split('=')[1];
    axios
      .get(MRRMovers.customerMovers + '/' + _id, Auth.getAuthConfig())
      .then(({data}) => {
        setMoversData({
          thirty: {
            percent:
              numberWithCommas(data.thirty_days.mrr_percent_change * 100) + '%',
            percent_indicator:
              data.thirty_days.mrr_percent_change > 0 ? 'positive' : 'negative',
            dollar: '$' + numberWithCommas(data.thirty_days.mrr_dollar_change),
            dollar_indicator:
              data.thirty_days.mrr_dollar_change > 0 ? 'positive' : 'negative',
          },
          seven: {
            percent:
              numberWithCommas(data.seven_days.mrr_percent_change * 100) + '%',
            percent_indicator:
              data.seven_days.mrr_percent_change > 0 ? 'positive' : 'negative',
            dollar: '$' + numberWithCommas(data.seven_days.mrr_dollar_change),
            dollar_indicator:
              data.seven_days.mrr_dollar_change > 0 ? 'positive' : 'negative',
          },
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="customer-details__header">
            <div className="customer-details__details">
              <div className="back-link">
                <span onClick={goBack}>{'< Back to report'}</span>
              </div>

              <div className="customer-short-info">
                <div className="customer-info">
                  <div className="customer-name">
                    {customerInfo.company_name}
                  </div>
                  <div className="customer-email">
                    {customerInfo.company_domain}
                  </div>
                </div>
              </div>
            </div>
            <div className="customer-details__movers">
              {Object.entries(moversData).map(([key, value]) => (
                <div className="mover">
                  <div className="mover__days">
                    {key === 'seven' ? '7d' : '30d'}
                  </div>
                  <div className="mover__data-holder">
                    <div className="mover__data">
                      <FontAwesomeIcon
                        icon={
                          value.percent_indicator === 'positive'
                            ? faChevronUp
                            : faChevronDown
                        }
                        className={
                          value.percent_indicator === 'positive'
                            ? 'mover__indicator-up'
                            : 'mover__indicator-down'
                        }
                      />{' '}
                      <span>{value.percent}</span>
                    </div>
                    <div className="mover__data">
                      <FontAwesomeIcon
                        icon={
                          value.dollar_indicator === 'positive'
                            ? faChevronUp
                            : faChevronDown
                        }
                        className={
                          value.dollar_indicator === 'positive'
                            ? 'mover__indicator-up'
                            : 'mover__indicator-down'
                        }
                      />{' '}
                      <span>{value.dollar}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {customerInfo.name !== null && (
            <ReportTable
              statistics={customerHistory}
              tableHeaders={tableHeaders}
              chartType="inLifeCustomerView"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InLifeCustomerView;
