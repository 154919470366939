import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import NumberFormat from 'react-number-format';

const CustomTooltip = (props) => {
  // console.log(props);

  if (props.active) {
    const {label, payload} = props;
    // console.log(payload);
    if (payload)
      return (
        <div className="custom-tooltip">
          <h6 className="custom-tooltip-date">
            {moment(label).format('MMM D YYYY')}
          </h6>
          <p>
            <NumberFormat
              value={payload[0].payload.totalPaidInvoices}
              displayType={'text'}
              thousandSeparator={true}
            />{' '}
            Paid Invoices
          </p>
          {/* <br /> */}
          {/* <h6>Including free trial</h6> */}
          <p>
            <NumberFormat
              value={payload[0].payload.totalInvoiceAmount}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="$"
            />{' '}
            Invoice Value
          </p>
          <p>
            <NumberFormat
              value={payload[0].payload.revenueShare}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="$"
            />{' '}
            Revenue Share
          </p>
          <br />
          {/* <h6>Excluding free trial</h6>
          <p>
            <NumberFormat
              value={payload[0].payload.totalInvoiceExclTrialAmount}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="$"
            />{' '}
            Invoice Value
          </p>
          <p>
            <NumberFormat
              value={payload[0].payload.revenueShareProjected}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="$"
            />{' '}
            Revenue Share
          </p> */}

          {/* <h6>Last Month</h6> */}
          {payload[0].payload.last_month !== undefined && (
            <div>
              <h6 className="custom-tooltip-last-month">
                Last Month
                <br />
                {moment(payload[0].payload.last_month.date).format(
                  'MMM D YYYY'
                )}
              </h6>
              <p>
                <NumberFormat
                  value={payload[0].payload.last_month.totalPaidInvoices}
                  displayType={'text'}
                  thousandSeparator={true}
                />{' '}
                Paid Invoices
              </p>
              {/* <br /> */}
              {/* <h6>Including free trial</h6> */}
              <p>
                <NumberFormat
                  value={payload[0].payload.last_month.totalInvoiceAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="$"
                />{' '}
                Invoice Value
              </p>
              <p>
                <NumberFormat
                  value={payload[0].payload.last_month.revenueShare}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="$"
                />{' '}
                Revenue Share
              </p>
              <br />
            </div>
          )}

          {/* <h6>Excluding free trial</h6>
          <p>
            <NumberFormat
              value={payload[0].payload.totalInvoiceExclTrialAmount}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="$"
            />{' '}
            Invoice Value
          </p>
          <p>
            <NumberFormat
              value={payload[0].payload.revenueShareProjected}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix="$"
            />{' '}
            Revenue Share
          </p> */}
        </div>
      );
  }

  return null;
};

const InvoiceValueChart = ({chartData}) => {
  const xAxisTickFormatter = (date) => {
    return moment(date).format('MMM D');
  };

  const yAxisTickFormatter = (cost_per_conversion) => {
    return '$' + cost_per_conversion;
  };

  const _chartData = chartData.map((data) => data);
  _chartData.sort();
  _chartData.reverse();

  // console.log();

  return (
    <div className="section-chart chart-marketing">
      <ResponsiveContainer width="100%" height={540}>
        <ComposedChart
          data={_chartData}
          width={100}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid
            vertical={false}
            stroke="#252422"
            strokeDasharray="1 1"
          />

          <XAxis
            dataKey="date"
            tickFormatter={xAxisTickFormatter}
            axisLine={false}
            tickLine={false}
            tickMargin={11}
            // interval={13}
            stroke="#252422"
            padding={{left: 0, right: 0}}
          />

          <YAxis
            yAxisId="left"
            axisLine={false}
            tickLine={false}
            tickMargin={7}
            stroke="#252422"
            type="number"
            tickCount={9}
          />

          <YAxis
            yAxisId="right"
            orientation="right"
            axisLine={false}
            tickFormatter={yAxisTickFormatter}
            tickLine={false}
            tickMargin={7}
            stroke="#252422"
            type="number"
            tickCount={9}
          />

          <Tooltip active={false} content={<CustomTooltip />} />

          <Legend />

          <Line
            yAxisId="left"
            // type="monotone"
            dataKey="totalPaidInvoices"
            stroke="#fe7f2d"
            strokeWidth={2}
          />

          <Bar
            yAxisId="right"
            dataKey="last_month.totalInvoiceAmount"
            barSize={20}
            stackId="a"
            fill="#fe7f2d"
          />

          <Bar
            yAxisId="right"
            dataKey="difference"
            barSize={20}
            stackId="a"
            fill="#243f5c"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InvoiceValueChart;
