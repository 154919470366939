import React, {useState} from 'react';

const TopBar = (props) => {
  const [activeCategory, setActiveCategory] = useState(props.categories[0]);
  const showSelectedCategory = (category) => {
    if (category === activeCategory) return 'category-selected';
    else return '';
  };
  const changeCategory = (category) => {
    setActiveCategory(category);
    props.callbackCategory(category);
  };
  return (
    <div className="top-bar">
      <div className="categories">
        {props.categories.map((category) => (
          <span
            className={showSelectedCategory(category)}
            onClick={() => changeCategory(category)}
            key={category}
          >
            {category}
          </span>
        ))}
      </div>
      {props.children}
    </div>
  );
};

export default TopBar;
