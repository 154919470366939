const BASE_URL = 'https://billsby-mailgun.onrender.com';
// const BASE_URL = process.env.REACT_APP_BE_URL;

export const AuthUrl = {
  verify: BASE_URL + '/api/login/verify',
};

export const CustomerSignUpsURL = {
  // Descending orders
  thirtyDays: BASE_URL + '/api/customer-signups/statistics',
  sixtyDays: BASE_URL + '/api/customer-signups/statistics?days=60',
  ninetyDays: BASE_URL + '/api/customer-signups/statistics?days=90',
  yearly: BASE_URL + '/api/customer-signups/statistics?year=1',
  // Ascending orders
  thirtyDaysASC: BASE_URL + '/api/customer-signups/statistics?order=asc',
  sixtyDaysASC: BASE_URL + '/api/customer-signups/statistics?days=60&order=asc',
  ninetyDaysASC:
    BASE_URL + '/api/customer-signups/statistics?days=90&order=asc',
  yearlyASC: BASE_URL + '/api/customer-signups/statistics?year=1&order=asc',
  custom: BASE_URL + '/api/customer-signups/statistics/custom',
  newFreeTrials: BASE_URL + '/api/customer-signups/statistics/free-trials/new',
};

export const marketingURLS = {
  statistics: BASE_URL + '/api/marketing/statistics',
};

export const MarketingConversionContent = {
  fetchAnalytics:
    'https://cors-anywhere.herokuapp.com/https://simpleanalytics.com/billsby.com.json',
};

export const AdBudget = {
  get: 'https://billsby-mailgun.onrender.com/api/ad-budget',
  update: 'https://billsby-mailgun.onrender.com/api/ad-budget/update',
  create: 'https://billsby-mailgun.onrender.com/api/ad-budget/create',
};

export const Invoice = {
  marketing: BASE_URL + '/api/marketing/statistics',
  all: BASE_URL + '/api/invoice/all',
  breakdown: BASE_URL + '/api/invoice/breakdown',
  dateBreakdown: BASE_URL + '/api/invoice/date-breakdown',
  getInvoiceFromDate: BASE_URL + '/api/invoice/date-range',
};

export const Evaluation = {
  getScored: BASE_URL + '/api/evaluation/scored-customers',
  getUnscored: BASE_URL + '/api/evaluation/unscored-customers',
  getUnscoredTotal: BASE_URL + '/api/evaluation/count-unscored-customers',
  updateCustomerInfo: BASE_URL + '/api/evaluation/update-customer-info',
  fetchCustomerInfo: BASE_URL + '/api/evaluation/fetch-customer-info',
  fetchLogs:
    BASE_URL + '/api/evaluation/fetch-update-logs?mode=evaluationCustomers',
  exportAsCsv: BASE_URL + '/api/csv/export',
};

export const InLife = {
  getCustomerList: BASE_URL + '/api/evaluation/fetch-in-life-customers',
  fetchLogs: BASE_URL + '/api/evaluation/fetch-update-logs?mode=inLife',
  fetchCustomerHistory:
    BASE_URL + '/api/evaluation/fetch-in-life-customers/info',
  exportAsCsv: BASE_URL + '/api/csv/export',
};

export const FailedPayment = {
  fetchData: BASE_URL + '/api/failed-payments/fetch',
  dateRange: BASE_URL + '/api/failed-payments/range',
};

export const Target = {
  fetchData: BASE_URL + '/api/target',
};

export const Migration = {
  saveSubscriptionClients: BASE_URL + '/api/migrate/subscriptions/client/add',
  fetchSubscriptionClients: BASE_URL + '/api/migrate/subscriptions/clients',
  uploadSubscriptions: BASE_URL + '/api/migrate/subscriptions/upload',
  fetchSubscriptionClientDetails:
    BASE_URL + '/api/migrate/subscriptions/client',
  deleteQueuedSubscriptions:
    BASE_URL + '/api/migrate/subscriptions/client/delete',

  saveCustomerClientsOnly: BASE_URL + '/api/migrate/customers/client/add',
  saveCustomerAndSubscriberClients:
    BASE_URL + '/api/migrate/customers/client/subscribers/add',
  fetchCustomerClients: BASE_URL + '/api/migrate/customers/clients',
  uploadCustomers: BASE_URL + '/api/migrate/customers/upload',
  fetchCustomerClientDetails: BASE_URL + '/api/migrate/customers/client',
  deleteQueuedCustomers: BASE_URL + '/api/migrate/customers/client/delete',
};

export const MRRMovers = {
  allMovers: BASE_URL + '/api/mrr-movers',
  customerMovers: BASE_URL + '/api/mrr-movers/customer',
};

export const partnersRevenueShare = {
  allPartners: BASE_URL + '/api/partners/',
  reportDates: BASE_URL + '/api/partners/report/date',
};

export const Pipeline = {
  byDate: BASE_URL + '/api/pipeline',
  export: BASE_URL + '/api/csv/export/pipeline',
};
