import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import moment from 'moment'

const Chart = ({ chartData }) => {
  const xAxisTickFormatter = (date) => {
    return moment(date).format('MMM D');
  }

  const toolTipFormatter = (date) => {
    return moment(date).format('MMM D YYYY');
  }

  return (
    <div className="section-chart">
      <ResponsiveContainer width="100%" height={540} >
        <LineChart
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>

          <CartesianGrid
            vertical={false}
            stroke="#252422"
            strokeDasharray="1 1" />

          <XAxis
            dataKey="date"
            tickFormatter={xAxisTickFormatter}
            axisLine={false}
            tickLine={false}
            tickMargin={11}
            interval={13}
            stroke="#252422" />

          <YAxis
            axisLine={false}
            tickLine={false}
            tickMargin={7}
            stroke="#252422"
            type="number"
            tickCount={10} />

          <Tooltip
            active={false}
            contentStyle={{ padding: '20px 20px 14px' }}
            labelFormatter={toolTipFormatter}
            labelStyle={{ marginBottom: '13px', fontFamily: 'Roboto', fontWeight: 700, fontSize: '17px', color: '#252422' }}
            cursor={{ stroke: '#252422', strokeWidth: 2 }} />

          <Legend />

          <Line
            type="linear"
            dataKey="signed_up"
            stroke="#d22630"
            strokeWidth={2} />

          <Line
            type="linear"
            dataKey="first_plan"
            stroke="#d25726"
            strokeWidth={2} />

          <Line
            type="linear"
            dataKey="pre_live"
            stroke="#d27f26"
            strokeWidth={2} />

          <Line
            type="linear"
            dataKey="card_on_file"
            stroke="#d2b526"
            strokeWidth={2} />

          <Line
            type="linear"
            dataKey="paying_customers"
            stroke="#25a970"
            strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </div >
  )
}

export default Chart;
