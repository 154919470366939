import React from 'react';

const SectionContent = (props) => {
  return (
    <div className="sectioncontent-container">
      <div className="content">
        <div className="title">{props.title}</div>
        <div className="details">{props.details} </div>
      </div>

      <div className="columns-container">
        <div className="column size-s">
          <div className="column-title">{props.data[0].date}</div>
          <div className="column-detail">{props.data[0].data}</div>
        </div>
        <div className="column size-m">
          <div className="column-title">
            {props.data[1] !== undefined ? props.data[1].date : ''}
          </div>
          <div className="column-detail">
            {props.data[1] !== undefined ? props.data[1].data : ''}
          </div>
        </div>
        <div className="column size-l">
          <div className="column-title">
            {props.data[2] !== undefined ? props.data[2].date : ''}
          </div>
          <div className="column-detail">
            {props.data[2] !== undefined ? props.data[2].data : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionContent;
