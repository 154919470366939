import React from 'react';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const CustomerVoice = () => {
  return (
    <>
      <Header isLoggedIn={true} />
      <div className="customer-voice body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="customer-voice-embed-code">
            <div style={{width: '100%', height: '100%'}}>
              <script>
                {(function () {
                  var on = window.addEventListener
                      ? function (m, c) {
                          window.addEventListener(m, c, false);
                        }
                      : function (m, c) {
                          window.attachEvent('on' + m, c);
                        },
                    r = function (e) {
                      if (e.origin !== 'https://my.g2.com') {
                        return;
                      }
                      var d = document.getElementById(
                        'g2-crowd-widget-testimonial-18114'
                      );
                      if (d) {
                        d.style.height = e.data;
                      }
                      r = function () {};
                    };
                  on('message', r);
                })()}
              </script>
              <iframe
                title="testimonial"
                frameBorder="0"
                height="100%"
                id="g2-crowd-widget-testimonial-18114"
                src="https://www.g2.com/products/billsby/testimonials/18114.embed"
                style={{width: '100%', minHeight: '100%', overflow: 'hidden'}}
                width="100%"
              ></iframe>
              <a
                style={{fontSize: '12px', color: '#446273', float: 'right'}}
                href="https://www.g2.com/products/billsby/reviews?utm_campaign=testimonials_embed&amp;utm_medium=testimonials&amp;utm_source=Billsby"
              >
                Billsby reviews sourced by G2
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerVoice;
