import React, {useState, useEffect} from 'react';
import auth from '../helpers/Auth';
import Select from 'react-select';
import CheckEmailAccess from '../helpers/CheckEmailAccess';

const ScoreCard = ({data, usage, onUpdateData, onSaveData}) => {
  const [contentMode, setContentMode] = useState('view');
  const [tempData, setTempData] = useState(data);
  const [lastIndustry, setLastIndustry] = useState(null);

  const changeContentMode = (mode) => {
    setContentMode(mode);
  };

  const scoreCardOptions = {
    mrr: ['< $10', '$10-$100', '$100-$1000', '$1000+', 'Unknown'],
    size: [
      'Lifestyle',
      'Startup',
      'Scale-up',
      'SME',
      'Inc 5000',
      'Enterprise',
      'Multi-national',
      'Unknown',
    ],
    industry: [
      {value: 'Fitness and wellbeing', label: 'Fitness and wellbeing'},
      {
        value: 'Government and Municipalities',
        label: 'Government and Municipalities',
      },
      {value: 'Internet of things', label: 'Internet of things'},
      {value: 'Media and entertainment', label: 'Media and entertainment'},
      {value: 'Membership and clubs', label: 'Membership and clubs'},
      {value: 'Property and utilities', label: 'Property and utilities'},
      {value: 'Healthcare and education', label: 'Healthcare and education'},
      {value: 'Travel and leisure', label: 'Travel and leisure'},
      {value: 'Software as a service', label: 'Software as a service'},
      {value: 'Others', label: 'Others'},
      {value: 'Unknown', label: 'Unknown'},
    ],
    migration: [
      '<100 subs',
      '100-1k subs',
      '1-10k subs',
      '10k+ subs',
      'Unknown',
    ],
    skill: ['Low', 'Medium', 'High', 'Unknown'],
  };

  const updateXFactor = (e) => {
    const newValue = parseInt(e.target.value);
    setTempData((prevState) => ({
      ...tempData,
      scorecard: {
        ...tempData.scorecard,
        xFactor: newValue,
      },
    }));
  };

  const updateDataBtn = (data, type) => {
    if (type === 'mrr')
      setTempData((prevState) => ({
        ...tempData,
        scorecard: {
          ...tempData.scorecard,
          mrr: data,
        },
      }));
    else if (type === 'size')
      setTempData((prevState) => ({
        ...tempData,
        scorecard: {
          ...tempData.scorecard,
          size: data,
        },
      }));
    else if (type === 'migration')
      setTempData((prevState) => ({
        ...tempData,
        scorecard: {
          ...tempData.scorecard,
          migration: data,
        },
      }));
    else if (type === 'skill')
      setTempData((prevState) => ({
        ...tempData,
        scorecard: {
          ...tempData.scorecard,
          skill: data,
        },
      }));
    else {
      console.log('invalid type');
    }
  };

  const updateIndustry = (e) => {
    const newValue = e.value;
    setTempData((prevState) => ({
      ...tempData,
      scorecard: {
        ...tempData.scorecard,
        industry: newValue,
      },
    }));
    setLastIndustry(newValue);
  };

  useEffect(() => {
    if (usage === 'save') setContentMode('save');
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    onUpdateData(tempData);
    //eslint-disable-next-line
  }, [tempData]);

  useEffect(() => {
    setTempData((oldData) => {
      var dataTempHolder = data;
      if (usage === 'save') dataTempHolder.scorecard.industry = lastIndustry;
      return dataTempHolder;
    });
    //eslint-disable-next-line
  }, [data]);

  const saveData = () => {
    onSaveData();
    changeContentMode('view');
  };

  return (
    <>
      <div className="scorecard-container">
        <div className="scorecard-header">
          <div className="scorecard-header-title">Scorecard</div>
          {CheckEmailAccess.isScoreCardAccessible(auth.get('email')) &&
            usage === 'edit' &&
            contentMode === 'view' && (
              <button
                className="scorecard-header-btn"
                onClick={() => changeContentMode('edit')}
              >
                Edit
              </button>
            )}

          {CheckEmailAccess.isScoreCardAccessible(auth.get('email')) &&
            usage === 'edit' &&
            contentMode === 'edit' && (
              <button className="scorecard-header-btn" onClick={saveData}>
                Save
              </button>
            )}
        </div>
        {contentMode === 'view' && (
          <div className="scorecard-content">
            <div className="scorecard-content-list">
              <b>MRR</b>
              <span>{tempData.scorecard.mrr}</span>
            </div>
            <div className="scorecard-content-list">
              <b>Size</b>
              <span>{tempData.scorecard.size}</span>
            </div>
            <div className="scorecard-content-list">
              <b>Industry</b>
              <span>{tempData.scorecard.industry}</span>
            </div>
            <div className="scorecard-content-list">
              <b>Migration</b>
              <span>{tempData.scorecard.migration}</span>
            </div>
            <div className="scorecard-content-list">
              <b>Skill</b>
              <span>{tempData.scorecard.skill}</span>
            </div>
            <div className="scorecard-content-list">
              <b>X-Factor</b>
              <span>{tempData.scorecard.xFactor}</span>
            </div>
          </div>
        )}

        {(contentMode === 'edit' || contentMode === 'save') && (
          <div className="scorecard-content">
            <div className="scorecard-content-list">
              <b>MRR</b>
              <div className="scorecard-content-choices">
                {scoreCardOptions.mrr.map((mrr) => (
                  <button
                    className={
                      tempData.scorecard.mrr === mrr
                        ? 'choice-active choice-btn'
                        : 'choice-btn'
                    }
                    key={mrr}
                    onClick={() => updateDataBtn(mrr, 'mrr')}
                  >
                    {mrr}
                  </button>
                ))}
              </div>
            </div>
            <div className="scorecard-content-list">
              <b>Size</b>
              <div className="scorecard-content-choices">
                {scoreCardOptions.size.map((size) => (
                  <button
                    className={
                      tempData.scorecard.size === size
                        ? 'choice-active choice-btn'
                        : 'choice-btn'
                    }
                    key={size}
                    onClick={() => updateDataBtn(size, 'size')}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>
            <div className="scorecard-content-list">
              <b>Industry</b>
              <div className="scorecard-content-choices">
                <Select
                  defaultValue={
                    tempData.scorecard.industry !== null
                      ? {
                          value: tempData.scorecard.industry,
                          label: tempData.scorecard.industry,
                        }
                      : scoreCardOptions.industry[10]
                  }
                  options={scoreCardOptions.industry}
                  onChange={updateIndustry}
                  className="scorecard-content-select"
                />
              </div>
            </div>
            <div className="scorecard-content-list">
              <b>Migration</b>
              <div className="scorecard-content-choices">
                {scoreCardOptions.migration.map((migration) => (
                  <button
                    className={
                      tempData.scorecard.migration === migration
                        ? 'choice-active choice-btn'
                        : 'choice-btn'
                    }
                    key={migration}
                    onClick={() => updateDataBtn(migration, 'migration')}
                  >
                    {migration}
                  </button>
                ))}
              </div>
            </div>
            <div className="scorecard-content-list">
              <b>Skill</b>
              <div className="scorecard-content-choices">
                {scoreCardOptions.skill.map((skill) => (
                  <button
                    className={
                      tempData.scorecard.skill === skill
                        ? 'choice-active choice-btn'
                        : 'choice-btn'
                    }
                    key={skill}
                    onClick={() => updateDataBtn(skill, 'skill')}
                  >
                    {skill}
                  </button>
                ))}
              </div>
            </div>
            <div className="scorecard-content-list">
              <b>X-Factor</b>
              <div className="scorecard-content-xfactor-slider">
                <div className="slidecontainer">
                  <input
                    type="range"
                    min="0"
                    max="40"
                    value={
                      tempData.scorecard.xFactor === null
                        ? 0
                        : tempData.scorecard.xFactor
                    }
                    className="slider"
                    onChange={updateXFactor}
                  />
                </div>
                <div className="scorecard-xfactor-counter">
                  {tempData.scorecard.xFactor}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScoreCard;
