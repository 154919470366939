import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ReportTable from '../components/ReportTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
// import {css} from '@emotion/core';
import DotLoader from 'react-spinners/DotLoader';

import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

import {MRRMovers} from '../helpers/Urls';
import Auth from '../helpers/Auth';

const MmrMovers = () => {
  const [datePickerLabel, setDatePickerLabel] = useState('Last 30 days');
  const [changePickerLabel, setChangePickerLabel] = useState('Positive change');
  const [isLoading, setIsLoading] = useState(false);

  const [percList, setPercList] = useState([]);
  const [valList, setValList] = useState([]);

  const dateSelection = ['Last 7 days', 'Last 30 days'];
  const changeSelection = ['Positive change', 'Negative Change'];

  const tableHeadersPerc = [
    {title: 'Company'},
    {title: 'MMR'},
    {title: '% Change'},
  ];

  const tableHeadersVal = [
    {title: 'Company'},
    {title: 'MMR'},
    {title: '$ Change'},
  ];

  const handleSelectDateDropdown = (date) => {
    setDatePickerLabel(date);
    let days = date.split(' ')[1];
    let change = changePickerLabel.split(' ')[0].toLowerCase();
    fetchMovers(days, change);
  };

  const handleChangeSelection = (type) => {
    setChangePickerLabel(type);
    let days = datePickerLabel.split(' ')[1];
    let change = type.split(' ')[0].toLowerCase();
    fetchMovers(days, change);
  };

  const fetchMovers = (days, change) => {
    setIsLoading(true);
    axios
      .get(
        MRRMovers.allMovers + `?days=${days}&type=${change}`,
        Auth.getAuthConfig()
      )
      .then(({data}) => {
        const _valList = data.topDollarChange.map((dollar) => {
          return {
            id: dollar.company_id,
            name: dollar.name,
            mrr: dollar.current_mrr,
            change: dollar.mrr_dollar_change,
            changeStatus: change,
            prefix: '$',
          };
        });

        const _percList = data.topPercentChange.map((dollar) => {
          return {
            id: dollar.company_id,
            name: dollar.name,
            mrr: dollar.current_mrr,
            change: dollar.mrr_percent_change * 100,
            changeStatus: change,
            suffix: '%',
          };
        });

        setValList(_valList);
        setPercList(_percList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let days = datePickerLabel.split(' ')[1];
    let change = changePickerLabel.split(' ')[0].toLowerCase();
    fetchMovers(days, change);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="body-holder mmr-movers">
        <Sidebar />
        <div className="section-main">
          <div className="filter-options filter-wrapper">
            <UncontrolledButtonDropdown>
              <DropdownToggle className="filter-head" caret>
                {datePickerLabel}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="icon-chevron-down"
                />
              </DropdownToggle>
              <DropdownMenu className="filter-menu">
                {dateSelection.map((date, index) => (
                  <DropdownItem
                    onClick={() => {
                      handleSelectDateDropdown(date);
                    }}
                    key={index}
                  >
                    {date}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>

            <UncontrolledButtonDropdown>
              <DropdownToggle className="filter-head" caret>
                {changePickerLabel}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="icon-chevron-down"
                />
              </DropdownToggle>
              <DropdownMenu className="filter-menu">
                {changeSelection.map((change, index) => (
                  <DropdownItem
                    onClick={() => {
                      handleChangeSelection(change);
                    }}
                    key={index}
                  >
                    {change}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>

          {isLoading ? (
            <div className="spinner-container">
              <DotLoader color={'#243f5c'} size={100} />
            </div>
          ) : (
            <div className="change-wrapper">
              <div className="change-table-wrapper">
                <ReportTable
                  statistics={valList}
                  tableHeaders={tableHeadersVal}
                  chartType="mrrReport"
                  hidePagination={true}
                />
              </div>

              <div className="change-table-wrapper">
                <ReportTable
                  statistics={percList}
                  tableHeaders={tableHeadersPerc}
                  chartType="mrrReport"
                  hidePagination={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MmrMovers;
