import React, {useEffect, useState, useContext} from 'react';
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import axios from 'axios';
import auth from './Auth';

import {Context} from '../contexts/Context';

const ProcessLoginLink = ({props}) => {
  const {updateEmail} = useContext(Context);

  const [flag, setFlag] = useState();
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const config = {
    headers: {Authorization: `Bearer ${query.get('token')}`},
  };

  const ValidateToken = () => {
    console.log(query.get('token'));
    axios
      .post(
        'https://billsby-mailgun.onrender.com/api/login',
        {test: 'test'},
        config
      )
      .then(({data}) => {
        console.log(data);
        if (data.message === 'Authentication passed') {
          updateEmail(data.linkData.email);
          auth.login(data.token, data.linkData.email, () => {
            history.push('/');
          });
        } else {
          setFlag(data.message);
        }
      });
  };

  useEffect(() => {
    ValidateToken();
  });

  return <div style={{padding: '150px'}}>{flag}</div>;
};

export default withRouter(ProcessLoginLink);
