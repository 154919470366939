import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import DotLoader from 'react-spinners/DotLoader';
import {CSVLink} from 'react-csv';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ReportTable from '../components/ReportTable';
import LogsTable from '../components/LogsTable';
import TopBar from '../components/TopBar';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {Context} from '../contexts/Context';

import {
  compareAge,
  compareMrrConverted,
  compareStageLvlReverse,
} from '../helpers/Sort';

import {InLife} from '../helpers/Urls';
import Auth from '../helpers/Auth';

const InLifeCustomers = () => {
  const {inLifeCustomerList, setInLifeCustomerList} = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const [pickerLabel, setPickerLabel] = useState(
    moment().subtract(1, 'days').format('MMM DD YYYY')
  );

  const [chosenDate, setChosenDate] = useState(moment().subtract(1, 'days'));

  const [csvData, setCsvData] = useState([]);

  const tableHeaders = [
    {title: 'MRR'},
    {title: 'Company'},
    {title: 'Age (days)'},
    {title: 'Stage'},
  ];

  const changeCategory = (category) => {
    var _prevList = inLifeCustomerList.map((prev) => prev);
    var newList = [];
    if (category === 'MRR') {
      newList = [..._prevList].sort(compareMrrConverted);
    }
    if (category === 'Stage') {
      newList = [..._prevList].sort(compareStageLvlReverse);
    }
    if (category === 'Age') {
      newList = [..._prevList].sort(compareAge);
    }

    console.log({_prevList, newList});

    setInLifeCustomerList(newList);
  };

  const fetchCSVReport = (date, type) =>
    axios
      .get(InLife.exportAsCsv, {
        params: {
          date: moment(date).format('YYYY-MM-DD'),
          type,
        },
      })
      .then(({data}) => setCsvData(data))
      .catch((error) =>
        console.log(
          '%c ⚠ error ',
          'color:yellow;background:black;padding:5px;',
          error.response
        )
      );

  const fetchCustomerList = (date) => {
    setIsLoading(true);
    axios
      .get(
        InLife.getCustomerList + '?custom_date=' + date,
        Auth.getAuthConfig()
      )
      .then(({data}) => {
        const sortedMRR = data
          .map((list) => {
            if (list.mrr_converted === undefined) {
              list.mrr_converted = 0;
            }
            return list;
          })
          .sort(compareMrrConverted);
        setInLifeCustomerList(sortedMRR);

        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleDateRangeEvent = (e, picker) => {
    const datePicked = picker.startDate;
    setChosenDate(datePicked);
    setPickerLabel(datePicked.format('MMM DD YYYY'));
    fetchCustomerList(datePicked.format('YYYY-MM-DD'));
  };

  useEffect(() => {
    const dateYesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    fetchCustomerList(dateYesterday);
    fetchCSVReport(dateYesterday, 'in-life');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCSVReport(chosenDate, 'in-life');
  }, [chosenDate]);

  const topBarCategories = ['MRR', 'Stage', 'Age'];

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="body-holder">
        <Sidebar />
        <div className="section-main in-life-customers">
          <TopBar
            categories={topBarCategories}
            callbackCategory={changeCategory}
          >
            <div className="wrapper">
              {!isLoading && (
                <div className="export-container">
                  <button className="export">
                    <CSVLink
                      data={csvData}
                      filename={`In-Life Customers ${moment(chosenDate).format(
                        'YYYY-MM-DD'
                      )}.csv`}
                    >
                      Export as .CSV
                    </CSVLink>
                  </button>
                </div>
              )}
              <div className="filter-options no-margin">
                <DateRangePicker
                  onApply={handleDateRangeEvent}
                  initialSettings={{
                    maxDate: moment().subtract(1, 'days'),
                    minDate: moment('20200729', 'YYYYMMDD'),
                    startDate: chosenDate,
                    endDate: chosenDate,
                    singleDatePicker: true,
                    alwaysShowCalendars: false,
                  }}
                >
                  <button className="filter-head dropdown-toggle btn btn-secondary">
                    {pickerLabel}
                  </button>
                  {/* <i className="fal fa-chevron-down"></i> */}
                </DateRangePicker>
              </div>
            </div>
          </TopBar>

          {isLoading ? (
            <div className="spinner-container">
              <DotLoader color={'#243f5c'} size={100} />
            </div>
          ) : (
            <>
              <ReportTable
                statistics={inLifeCustomerList}
                tableHeaders={tableHeaders}
                chartType="inLifeCustomers"
              />

              <LogsTable mode={'inLife'} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InLifeCustomers;
