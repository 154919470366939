import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import SectionContent from '../components/SectionContent';
import TargetChart from '../components/TargetChart';

import {Target} from '../helpers/Urls';
import Auth from '../helpers/Auth';

const Target30m = () => {
  const [pickerLabel, setPickerLabel] = useState('December 2020');
  const [latestUpdate, setLatestUpdate] = useState('December 2020');
  const [latestMonth, setLatestMonth] = useState({});
  const [threeMonthsAgo, setThreeMonthsAgo] = useState({
    month: 'September',
    multiplier: '1762x',
  });
  const [sixMonthsAgo, setSixMonthsAgo] = useState({
    month: 'June',
    multiplier: '71676x',
  });

  const [multiplierGroup, setMultiplierGroup] = useState([
    {date: '', data: ''},
    {date: '', data: ''},
    {date: '', data: ''},
  ]);

  const [customerGrowthGroup, setCustomerGrowthGroup] = useState([
    {date: '', data: ''},
    {date: '', data: ''},
    {date: '', data: ''},
  ]);

  const [cardOnFileGroup, setCardOnFileGroup] = useState([
    {date: '', data: ''},
    {date: '', data: ''},
    {date: '', data: ''},
  ]);

  const [InvoiceValueGroup, setInvoiceValueGroup] = useState([
    {date: '', data: ''},
    {date: '', data: ''},
    {date: '', data: ''},
  ]);

  const [ChartData, setChartData] = useState([]);

  const [dateSelection, setDateSelection] = useState([]);

  const sectionContent = {
    multiplier: {
      title: 'The Multiplier - Target 1x',
      details:
        'Everything we do across all of our teams at Billsby helps to drive the multiplier - how many times we need to grow our invoice value to hit $30m per month. Even if you’re not responsible for dealing with our customers, your actions help to get the multiplier to our 1x target.',
    },
    customer_growth: {
      title: 'Customer Growth - Impacts The Multiplier in 3-12 months',
      details:
        'When we add more customers they sign up and try Billsby for the first time. As long as we impress them with great support, timely product updates and new features, and excellent engagement it’s likely that they’ll keep working with us to develop their product.',
    },
    card_on_file: {
      title: 'Card-on-File - Impacts The Multiplier in 1-3 months',
      details:
        'When customers put a card-on-file, they’re telling us that they’re about ready to go live. Engaging with them, understanding their issues and problems and helping them to get over-the-line speeds up their implementation, helping them to faster impact the multiplier.',
    },
    invoice_value: {
      title: 'Invoice Value - Impacts The Multiplier immediately',
      details:
        'Migrating customers to Billsby, helping customers to grow by recommending our features and services, getting customers over the line and simplifying our customers lives by ensuring a reliable, timely service drives more invoice value - the key factor in our growth.',
    },
  };

  const handleRedirect = (path) => {
    window.location.replace('/#' + path);
  };

  const populateData = (date) => {
    axios
      .get(Target.fetchData + `?date=${date}`, Auth.getAuthConfig())
      .then(({data}) => {
        console.log(data);
        // Set chart data
        setChartData(data);

        // Set Picker Label
        setPickerLabel(data[data.length - 1].date);

        // Set Latest Update
        setLatestUpdate(data[data.length - 1].date);

        // Set Months
        setLatestMonth({
          month: data[data.length - 1].date,
          multiplier: data[data.length - 1].multiplier_formatted,
        });
        setThreeMonthsAgo({
          month: data.length < 4 ? '' : data[data.length - 4].date,
          multiplier:
            data.length < 4 ? '' : data[data.length - 4].multiplier_formatted,
        });
        setSixMonthsAgo({
          month: data.length < 7 ? '' : data[data.length - 7].date,
          multiplier:
            data.length < 7 ? '' : data[data.length - 7].multiplier_formatted,
        });

        // Set Date Collection
        // Set Multiplier Group
        // Set Card On File Group
        // Set Invoice Value Group
        // Set Customer Growth Group
        var _multiplier_group = [];
        var _cardOnFile_group = [];
        var _invoice_value_group = [];
        var _customer_growth_group = [];
        let _dateSelection = data.map((value, index) => {
          const pushData = () => {
            _multiplier_group.push({
              date: value.date,
              data: value.multiplier_formatted,
            });
            _cardOnFile_group.push({
              date: value.date,
              data: value.card_on_file,
            });
            _invoice_value_group.push({
              date: value.date,
              data: value.invoice_value_growth,
            });
            _customer_growth_group.push({
              date: value.date,
              data: value.customer_growth,
            });
          };
          if (data.length > 4 && index > data.length - 4) {
            pushData();
          } else {
            if (data.length < 4) {
              console.log('lessthan 4');
              pushData();
            }
            if (data.length === 4) {
              if (index !== 0) pushData();
            }
          }

          return value.date;
        });

        if (dateSelection.length === 0) {
          const reverseSelection = _dateSelection.reverse();
          setDateSelection(reverseSelection);

          // setTimeout(() => {
          //   console.log(reverseSelection);
          // }, 3);
        }

        setMultiplierGroup(_multiplier_group);
        setCustomerGrowthGroup(_customer_growth_group);
        setCardOnFileGroup(_cardOnFile_group);
        setInvoiceValueGroup(_invoice_value_group);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    populateData(moment().format('MM-DD-YYYY'));
    //eslint-disable-next-line
  }, []);

  const handleSelectDateDropdown = (date) => {
    const formatDate = moment(date, 'MMMM YYYY')
      .add(1, 'months')
      .endOf('month')
      .format('MM-DD-YYYY');

    console.log(formatDate);

    populateData(formatDate);

    setPickerLabel(date);
  };

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="target-30m body-holder">
        <Sidebar />

        <div className="section-main">
          <div className="filter-options">
            <UncontrolledButtonDropdown>
              <DropdownToggle className="filter-head" caret>
                {pickerLabel}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="icon-chevron-down"
                />
              </DropdownToggle>
              <DropdownMenu className="filter-menu">
                {dateSelection.map((date, index) => (
                  <DropdownItem
                    onClick={() => {
                      handleSelectDateDropdown(date);
                    }}
                    key={index}
                  >
                    {date}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>

          <div className="sub-section section-1-update">
            <div className="section-content">
              <div className="section-title">
                Target $30,000,000: {latestUpdate} update
              </div>
              <p>
                A simple goal - but a lofty ambition. To get the company to a
                stage where we're on a path to world billing domination, we need
                to be processing <b>$30,000,000</b> of invoices per month. At
                the end of {latestMonth.month}, we need to grow the business{' '}
                <b>{latestMonth.multiplier}</b> bigger
                {threeMonthsAgo.month !== '' ? (
                  <>
                    . Sounds like a lot, but just three months ago in{' '}
                    {threeMonthsAgo.month}, we needed to grow{' '}
                    <b>{threeMonthsAgo.multiplier}</b> larger
                  </>
                ) : (
                  '.'
                )}
                {sixMonthsAgo.month !== '' ? (
                  <>
                    , and six months ago in {sixMonthsAgo.month} that number was{' '}
                    <b>{sixMonthsAgo.multiplier}</b>.
                  </>
                ) : sixMonthsAgo.month === '' && threeMonthsAgo.month === '' ? (
                  ''
                ) : (
                  '.'
                )}
                <br />
                <br />
                Together, we can all work to drive our growth multiplier down to
                1x and unlock the full potential of Billsby. Here’s an update on
                how things are going, and the metrics that matter.{' '}
              </p>
            </div>
            <div className="section-image">
              <img
                className="image-billsbot"
                src={require('../assets/images/billsbot-money.svg')}
                alt="billsbot-placeholder"
              />
            </div>
          </div>

          <div className="sub-section section-2-target-chart">
            <TargetChart chartData={ChartData} />
          </div>

          <div className="sub-section section-3-the-multiplier">
            <SectionContent
              title={sectionContent.multiplier.title}
              details={sectionContent.multiplier.details}
              data={multiplierGroup}
            />
          </div>

          <div className="sub-section section-4-customer-growth">
            <SectionContent
              title={sectionContent.customer_growth.title}
              details={sectionContent.customer_growth.details}
              data={customerGrowthGroup}
            />
          </div>

          <div className="sub-section section-5-card-on-file">
            <SectionContent
              title={sectionContent.card_on_file.title}
              details={sectionContent.card_on_file.details}
              data={cardOnFileGroup}
            />
          </div>

          <div className="sub-section section-6-invoice-value">
            <SectionContent
              title={sectionContent.invoice_value.title}
              details={sectionContent.invoice_value.details}
              data={InvoiceValueGroup}
            />
          </div>

          <div className="sub-section dive-deeper">
            <div className="section-title">Dive Deeper</div>

            <div className="section-details">
              Explore daily updates on company performance
            </div>

            <div className="tags-container">
              <div className="tag-group">
                <button
                  className="tag"
                  onClick={() => handleRedirect('/customer-signups')}
                >
                  Customer signups
                </button>
                <button
                  className="tag"
                  onClick={() => handleRedirect('/evaluation-customers')}
                >
                  Evaluation Customers
                </button>
                <button
                  className="tag"
                  onClick={() => handleRedirect('/in-life-customers')}
                >
                  In-life customers
                </button>
              </div>
              <div className="tag-group">
                <button
                  className="tag"
                  onClick={() => handleRedirect('/invoice-value')}
                >
                  Invoice-value
                </button>
                <button
                  className="tag"
                  onClick={() => handleRedirect('/marketing-conversion')}
                >
                  Marketing site conversion
                </button>
                <button
                  className="tag"
                  onClick={() => handleRedirect('/customer-voice')}
                >
                  Customer voice
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Target30m;
