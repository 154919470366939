import React, {useEffect, useContext} from 'react';
import Axios from 'axios';

import {Evaluation, InLife} from '../helpers/Urls';
import {Context} from '../contexts/Context';
import Auth from '../helpers/Auth';

const LogsTable = ({mode}) => {
  const {logList, setLogList, lastLogs, setLastLogs} = useContext(Context);

  useEffect(() => {
    if (lastLogs === '') setLastLogs(mode);

    if (mode === 'inLife' && lastLogs !== 'inLife') {
      Axios.get(InLife.fetchLogs, Auth.getAuthConfig())
        .then(({data}) => {
          setLogList(data);
          setLastLogs(mode);
        })
        .catch((err) => console.log(err));
    } else if (
      mode === 'evaluationCustomers' &&
      lastLogs !== 'evaluationCustomers'
    ) {
      Axios.get(Evaluation.fetchLogs, Auth.getAuthConfig())
        .then(({data}) => {
          setLogList(data);
          setLastLogs(mode);
        })
        .catch((err) => console.log(err));
    } else {
    }
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="logs-table">
        <div className="logs-table-title">
          <span>Recent changes</span>
        </div>
        <div className="logs-list">
          {logList.length > 0 ? (
            logList.map((data) => (
              <div className="logs-content" key={data.date}>
                <div className="logs-title">{data.date}</div>
                <div className="logs-description">
                  {data.logs.map((log) => (
                    <div key={log.log}>
                      <div dangerouslySetInnerHTML={{__html: log.log}} />
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="logs-content">
              <div className="logs-no-data">No recent changes</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LogsTable;
