export const compareMrrConverted = (a, b) => {
  if (!a.hasOwnProperty('mrr_converted') || !b.hasOwnProperty('mrr_converted'))
    return 0;

  const dataA = a.mrr_converted;
  const dataB = b.mrr_converted;

  if (dataA === dataB) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  // If dataB is equal to null increase index automatically
  if (dataB === null) return -1;
  if (dataA > dataB) return -1;
  if (dataA < dataB) return 1;

  return 0;
};

export const compareScore = (a, b) => {
  const dataA = a.score;
  const dataB = b.score;

  if (dataA === dataB) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  let comparison = 0;
  if (dataA > dataB) {
    comparison = -1;
  } else if (dataA < dataB) {
    comparison = 1;
  }
  return comparison;
};

export const compareStageLvl = (a, b) => {
  const dataA = a.stage_level;
  const dataB = b.stage_level;

  if (dataA === dataB) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }
  let comparison = 0;
  if (dataA > dataB) {
    comparison = 1;
  } else if (dataA < dataB) {
    comparison = -1;
  }
  return comparison;
};

export const compareStageLvlReverse = (a, b) => {
  const dataA = a.stage_level;
  const dataB = b.stage_level;
  if (dataA === dataB) {
    // return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    if (b.mrr_converted === null) return -1;
    if (a.mrr_converted > b.mrr_converted) return -1;
    if (a.mrr_converted < b.mrr_converted) return 1;
  }
  let comparison = 0;
  if (dataA > dataB) {
    comparison = -1;
  } else if (dataA < dataB) {
    comparison = 1;
  }
  return comparison;
};

export const compareIndustry = (a, b) => {
  const dataA = a.industry;
  const dataB = b.industry;

  if (dataA === dataB) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  let comparison = 0;
  if (dataA > dataB) {
    comparison = 1;
  } else if (dataA < dataB) {
    comparison = -1;
  }
  return comparison;
};

export const compareAge = (a, b) => {
  // const computeAge = (date) => {
  //   var difference = moment().diff(moment(date), 'days');
  //   return difference;
  // };

  // const dataA = computeAge(a.created_on);
  // const dataB = computeAge(b.created_on);
  let aAge = a.age.split(' ');
  let bAge = b.age.split(' ');

  const dataA = parseInt(aAge[0]);
  const dataB = parseInt(bAge[0]);

  if (dataA === dataB) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  let comparison = 0;
  if (dataA > dataB) {
    comparison = 1;
  } else if (dataA < dataB) {
    comparison = -1;
  }
  return comparison;
};

export const compareSize = (a, b) => {
  const computeSize = (size) => {
    if (size === 'Lifestyle') return 1;
    else if (size === 'Startup') return 2;
    else if (size === 'Scale-up') return 3;
    else if (size === 'SME') return 4;
    else if (size === 'Inc 5000') return 5;
    else if (size === 'Enterprise') return 6;
    else if (size === 'Multi-national') return 7;
    else return 0;
  };

  const dataA = computeSize(a.size);
  const dataB = computeSize(b.size);

  if (dataA === dataB) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  let comparison = 0;
  if (dataA > dataB) {
    comparison = -1;
  } else if (dataA < dataB) {
    comparison = 1;
  }
  return comparison;
};

export const compareMrrLvl = (a, b) => {
  const computeMrr = (size) => {
    if (size === '< $10') return 10;
    else if (size === '$10-$100') return 101;
    else if (size === '$100-$1000') return 1001;
    else if (size === '$1000+') return 10001;
    else return 0;
  };
  const dataA = computeMrr(a.mrr);
  const dataB = computeMrr(b.mrr);

  if (dataA === dataB) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }

  let comparison = 0;
  if (dataA > dataB) {
    comparison = -1;
  } else if (dataA < dataB) {
    comparison = 1;
  }
  return comparison;
};
