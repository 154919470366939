import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {hot} from 'react-hot-loader';

//context
import ContextProvider from './contexts/Context';

//routes for pages that requires user login
import ProtectedRoute from './helpers/ProtectedRoute';
import ProtectedLogin from './helpers/ProtectedLogin';
import ProcessLoginLink from './helpers/ProcessLoginLink';

// pages
import CustomerSignUps from './containers/CustomerSignups';
import About from './containers/About';
import Login from './containers/Login';
import InvoiceValue from './containers/InvoiceValue';
import EvaluationCustomers from './containers/EvaluationCustomers';
import MarketingConversion from './containers/MarketingConversion';
import CustomerVoice from './containers/CustomerVoice';
import PartnerRevenueShare from './containers/PartnerRevenueShare';
import MrrMovers from './containers/MrrMovers';
import UpdateSpendData from './containers/UpdateSpendData';
import Breakdown from './containers/Breakdown';
import CustomerEdit from './containers/CustomerEdit';
import CompanyEvaluation from './containers/CompanyEvaluation';
import InLifeCustomers from './containers/InLifeCustomers';
import InLifeCustomerView from './containers/InLifeCustomerView';
import Target30m from './containers/Target30m';
import PipelineAge from './containers/PipelineAge';

import NoRouteMatch from './containers/404';

//components
import Dropzone from './components/Dropzone';

import './stylesheets/main.scss';

class App extends Component {
  render() {
    return (
      <HashRouter basename={process.env.PUBLIC_URL}>
        <ContextProvider>
          <div className="App">
            <Switch>
              <ProtectedRoute
                path={['/', '/target']}
                exact
                component={Target30m}
              />
              <ProtectedRoute
                path="/customer-signups"
                exact
                component={CustomerSignUps}
              />
              <ProtectedRoute
                path="/evaluation-customers"
                exact
                component={EvaluationCustomers}
              />
              <ProtectedRoute
                path="/invoice-value"
                exact
                component={InvoiceValue}
              />
              <ProtectedRoute
                path="/marketing-conversion"
                exact
                component={MarketingConversion}
              />
              <ProtectedRoute
                path="/customer-voice"
                exact
                component={CustomerVoice}
              />
              <ProtectedRoute path="/mrr-movers" exact component={MrrMovers} />
              <ProtectedRoute
                path="/partner-revenue-share"
                exact
                component={PartnerRevenueShare}
              />
              <ProtectedRoute
                path="/pipeline-age"
                exact
                component={PipelineAge}
              />
              <ProtectedRoute
                path="/update-spend-data"
                exact
                component={UpdateSpendData}
              />
              <ProtectedRoute
                path="/invoice-value/currency-breakdown/:date+"
                exact
                component={Breakdown}
              />
              <ProtectedRoute
                path="/evaluation-customers/customer/:company"
                exact
                component={CustomerEdit}
              />
              <ProtectedRoute
                path="/evaluation-customers/company-evaluation"
                exact
                component={CompanyEvaluation}
              />
              <ProtectedRoute
                path="/customer/:company"
                exact
                component={InLifeCustomerView}
              />
              <ProtectedRoute
                path="/in-life-customers"
                exact
                component={InLifeCustomers}
              />

              {/* Tools 
              <ProtectedRoute
                path="/tools/failed-payment-lookup"
                exact
                component={FailedPaymentLookup}
              />
              <ProtectedRoute path="/scheduler" exact component={Scheduler} />
              <ProtectedRoute
                path="/tools/migrate-customers"
                exact
                component={MigrateCustomers}
              />
              <ProtectedRoute
                path="/tools/migrate-customers/add-client"
                exact
                component={AddCustomerMigrationClient}
              />
              <ProtectedRoute
                path="/tools/migrate-customers/:client"
                exact
                component={CustomerClientView}
              />
              <ProtectedRoute
                path="/tools/migrate-customers/:client/csvupload"
                exact
                component={CustomerClientUpload}
              />
              <ProtectedRoute
                path="/tools/migrate-customers/:client/:date"
                exact
                component={CustomerClientHistory}
              />
              <ProtectedRoute
                path="/tools/migrate-subscriptions"
                exact
                component={MigrateSubscriptions}
              />
              <ProtectedRoute
                path="/tools/migrate-subscriptions/add-client"
                exact
                component={AddSubscriptionMigrationClient}
              />
              <ProtectedRoute
                path="/tools/migrate-subscriptions/:client"
                exact
                component={SubscriptionClientView}
              />
              <ProtectedRoute
                path="/tools/migrate-subscriptions/:client/csvupload"
                exact
                component={SubscriptionClientUpload}
              />
              <ProtectedRoute
                path="/tools/migrate-subscriptions/:client/:date"
                exact
                component={SubscriptionClientHistory}
              />
              */}

              <ProtectedLogin path="/login" exact component={Login} />
              <Route path="/pl" component={ProcessLoginLink} />
              <Route path="/about" component={About} />
              <Route path="/dropzone" component={Dropzone} />
              <Route component={NoRouteMatch} />
            </Switch>
          </div>
        </ContextProvider>
      </HashRouter>
    );
  }
}

export default hot(module)(App);
