const scoreCardAccess = [
  'karl@fullstack.ph',
  'khalvin@fullstack.ph',
  'hazelle@fullstack.ph',
  'gemma.beeharee@airfinetworks.com',
  'alex.russell@airfinetworks.com',
  'omaris@billsby.com',
  'jakec@billsby.com',
  'saqibi@billsby.com',
  'morganh@billsby.com',
];

class CheckEmailAccess {
  isScoreCardAccessible(email) {
    return scoreCardAccess.includes(email);
  }
}

export default new CheckEmailAccess();
