import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import DotLoader from 'react-spinners/DotLoader';

import auth from '../helpers/Auth';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import DataCard from '../components/DataCard';

import MarketingConversionChart from '../components/MarketingConversionChart.js';
import ReportTable from '../components/ReportTable';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import global from '../helpers/Global';

import {marketingURLS} from '../helpers/Urls';

const MarketingConversion = () => {
  const tableHeaders = [
    {title: 'Date'},
    {title: 'Unique visitors'},
    {title: 'Sign-ups'},
    {title: 'Conversion rate'},
    {title: 'Ad Budget'},
    {title: 'Cost per conversion'},
  ];

  const ranges = {
    'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
    'Last 30 Days': [
      moment().subtract(30, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 60 Days': [
      moment().subtract(60, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 90 Days': [
      moment().subtract(90, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 180 Days': [
      moment().subtract(180, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 365 Days': [
      moment().subtract(365, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
  };

  const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'days'));
  const [isLoading, setIsLoading] = useState(false);

  const [pickerLabel, setPickerLabel] = useState('Last 30 days');

  const handleDateRangeEvent = (e, picker) => {
    var start = picker.startDate,
      end = picker.endDate;

    setStartDate(start);
    setEndDate(end);

    if (
      start.format('MMM DD YYYY') ===
        moment().subtract(7, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 7 days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(30, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 30 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(60, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 60 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(90, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 90 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(180, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 180 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(365, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 365 Days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(1, 'year').startOf('year').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') === moment().subtract(1, 'year').endOf('year')
    ) {
      setPickerLabel('Last Year');
    } else {
      setPickerLabel(
        start.format('MMM DD YYYY') + ' - ' + end.format('MMM DD YYYY')
      );
    }
  };

  const [statistics, setStatistics] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [dataBoxes, setDataBoxes] = useState({
    sumOfUniques: 0,
    sumOfSignups: 0,
    conversionRate: 0,
    sumOfAdBudgets: 0,
    costPerConversion: 0,
  });

  const fetchMarketingStats = () => {
    setIsLoading(true);
    var endpoint = marketingURLS.statistics,
      sumUniques = 0,
      sumSignups = 0,
      sumAdBudget = 0;

    axios
      .get(
        endpoint +
          '?start=' +
          startDate.format('YYYY-MM-DD') +
          '&end=' +
          endDate.format('YYYY-MM-DD') +
          '&order=DESC',
        auth.getAuthConfig()
      )
      .then(({data}) => {
        setStatistics(data);
        // console.log(statistics.length);

        data.forEach((item) => {
          sumUniques = sumUniques + item.uniques;
          sumSignups = sumSignups + item.sign_ups;
          sumAdBudget = sumAdBudget + item.ad_budget;
        });

        setDataBoxes({
          sumOfUniques: global.marketingNullChecker(sumUniques),
          sumOfSignups: global.marketingNullChecker(sumSignups),
          conversionRate: global.marketingNullChecker(
            (sumSignups / sumUniques) * 100
          ),
          sumOfAdBudgets: global.marketingNullChecker(sumAdBudget),
          costPerConversion: global.marketingNullChecker(
            sumAdBudget / sumSignups
          ),
        });

        // console.log('sumCp = ' + dataBoxes.conversionRate);

        const reversed = [...data].reverse();
        setChartData(reversed);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchMarketingStats();
    //eslint-disable-next-line
  }, [startDate, endDate]);

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="marketing-conversion body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="filter-options mc-filter">
            <DateRangePicker
              onApply={handleDateRangeEvent}
              initialSettings={{
                startDate: startDate,
                endDate: endDate,
                ranges: ranges,
                maxDate: moment().subtract(1, 'days'),
                alwaysShowCalendars: true,
              }}
            >
              <button className="filter-head dropdown-toggle btn btn-secondary">
                {pickerLabel}
              </button>
              {/* <i className="fal fa-chevron-down"></i> */}
            </DateRangePicker>
            {(auth.get('email') === 'marianne@fullstack.ph' ||
              auth.get('email') === 'monica@fullstack.ph' ||
              auth.get('email') === 'morganh@billsby.com') && (
              <Link to="/update-spend-data" className="link">
                Update ad spend data
              </Link>
            )}
          </div>

          {isLoading ? (
            <div className="spinner-container">
              <DotLoader color={'#243f5c'} size={100} />
            </div>
          ) : (
            <>
              {statistics.length > 0 ? (
                <div className="with-results">
                  {chartData.length > 0 && (
                    <MarketingConversionChart chartData={chartData} />
                  )}

                  <div className="data-card-holder">
                    <DataCard
                      heading="Unique visitors"
                      dataValue={
                        <NumberFormat
                          value={dataBoxes.sumOfUniques}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                      }
                    />
                    <DataCard
                      heading="Sign-ups"
                      dataValue={
                        <NumberFormat
                          value={dataBoxes.sumOfSignups}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                      }
                    />
                    <DataCard
                      heading="Conversion rate"
                      dataValue={
                        <NumberFormat
                          value={dataBoxes.conversionRate}
                          displayType={'text'}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          suffix="%"
                        />
                      }
                    />
                    <DataCard
                      heading="Cost per conversion"
                      dataValue={
                        <NumberFormat
                          value={dataBoxes.costPerConversion}
                          displayType={'text'}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          prefix="$"
                        />
                      }
                    />
                  </div>

                  <ReportTable
                    statistics={statistics}
                    tableHeaders={tableHeaders}
                    chartType="marketingConversion"
                  />
                </div>
              ) : (
                <div className="no-results">
                  <img
                    src={require('../assets/images/404@2x.png')}
                    alt="no-results"
                  />
                  <h4>No Results</h4>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MarketingConversion;
