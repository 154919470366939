import React, {createContext, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {Migration} from '../helpers/Urls';
import axios from 'axios';
import Auth from '../helpers/Auth';

export const Context = createContext();

export const ContextProvider = ({children}) => {
  var history = useHistory();

  const [dateUpdated, setDateUpdated] = useState(null);
  const [email, setEmail] = useState('');
  const [activeModule, setActiveModule] = useState('');
  const [newTrialCustomers, setNewTrialCustomers] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const [inLifeCustomerList, setInLifeCustomerList] = useState([]);
  const [logList, setLogList] = useState([]);
  const [lastLogs, setLastLogs] = useState('');

  const [selectedClient, setSelectedClient] = useState({});

  // CSV Upload States
  const [acceptedRows, setAcceptedRows] = useState([]);
  const [rejectedRows, setRejectedRows] = useState([]);

  // History Report States
  const [reportSuccess, setReportSuccess] = useState([]);
  const [reportErrors, setReportErrors] = useState([]);

  const updateDateUpdated = (newDate) => {
    if (newDate) localStorage.setItem('billsby-last-update', newDate);
    setDateUpdated(newDate);
  };

  const updateEmail = (newEmail) => {
    setEmail(newEmail);
  };

  const updateActiveModule = (newActiveModule) => {
    setActiveModule(newActiveModule);
  };

  const handleImportCSV = (file, type) => {
    return new Promise((resolve, reject) => {
      console.log('Uploading CSV ');
      const fileChecker = true;

      if (fileChecker) {
        let url = Migration.uploadSubscriptions;

        if (type === 'customers') url = Migration.uploadCustomers;

        let formData = new FormData();
        formData.append('csvfile', file);
        formData.append('client_id', selectedClient.client_id);
        formData.append('client_name', selectedClient.client_name);

        axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((result) => {
            console.log(result);
            setAcceptedRows(result.data.accepted);
            setRejectedRows(result.data.rejected);
            resolve(result);
          })
          .catch((err) => {
            console.log(err.response.data);
            reject();
          });
      } else {
        console.log('error csv');
        reject();
      }
    });
  };

  const openHistoryReport = (report, type) => {
    var path = '';
    console.log(report.scheduled);
    var date;

    console.log(report);

    if (type === 'subscriptions') {
      date = report.scheduled.split(' ');
      path = date[0];
    } else {
      path = report.scheduled;
    }

    history.push(`${history.location.pathname}/${path}`);
  };

  const checkAuth = async () => {
    if (Auth.isAuthenticated()) {
      await Auth.checkAuthCredentials().then((result) => {
        if (!result) {
          console.log('Invalid Auth credentials, Logging out user!');
          Auth.logout();
        }
      });
    }
  };

  useEffect(() => {
    // console.log('Context API Running');
    checkAuth();
  }, []);

  return (
    <Context.Provider
      value={{
        dateUpdated,
        updateDateUpdated,
        email,
        updateEmail,
        activeModule,
        updateActiveModule,
        newTrialCustomers,
        setNewTrialCustomers,
        customerList,
        setCustomerList,
        logList,
        setLogList,
        lastLogs,
        setLastLogs,
        inLifeCustomerList,
        setInLifeCustomerList,
        selectedClient,
        setSelectedClient,
        handleImportCSV,
        history,
        rejectedRows,
        acceptedRows,

        reportSuccess,
        setReportSuccess,
        reportErrors,
        setReportErrors,
        openHistoryReport,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default ContextProvider;
