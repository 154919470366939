import React, {useState, useEffect} from 'react';
import moment from 'moment';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Auth from '../helpers/Auth';
import axios from 'axios';
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import {CSVLink} from 'react-csv';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {Target, Pipeline} from '../helpers/Urls';
import DotLoader from 'react-spinners/DotLoader';

const PipelineAge = () => {
  const [pickerLabel, setPickerLabel] = useState('All Customers');
  const [dateSelection, setDateSelection] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleOptionSelect = async (option) => {
    setPickerLabel(option);
    setIsLoading(true);
    await fetchPipeline(option);
    setIsLoading(false);
  };

  const populateData = (date) =>
    axios
      .get(Target.fetchData + `?date=${date}`, Auth.getAuthConfig())
      .then(({data}) => {
        console.log(
          '%c ⚠ request ',
          'color:yellow;background:black;padding:5px;'
        );
        // setPickerLabel(data[data.length - 1].date);
        let _dateSelection = data.map((value, index) => {
          return value.date;
        });

        if (dateSelection.length === 0) {
          const reverseSelection = _dateSelection.reverse();
          // setDateSelection(reverseSelection);

          setDateSelection((prevSelection) => {
            var selection = ['All Customers'];
            reverseSelection.forEach((d) => selection.push(d));
            return selection;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

  const fetchPipeline = (date) =>
    Promise.all([
      axios.get(
        Pipeline.byDate,
        date !== 'All Customers'
          ? {
              params: {
                cohort: date,
              },
            }
          : ''
      ),
      axios.get(
        Pipeline.export,
        date !== 'All Customers'
          ? {
              params: {
                cohort: date,
              },
            }
          : ''
      ),
    ])
      .then((result) => {
        const pipelineResult = result[0].data;
        const exportData = result[1].data;

        setPipelineData(pipelineResult);
        setCsvData(exportData);
      })
      .catch((error) => {
        console.log(
          '%c ⚠ error ',
          'color:yellow;background:black;padding:5px;',
          error.response
        );
        setIsLoading(false);
      });

  useEffect(() => {
    (async function () {
      await populateData(moment().format('MM-DD-YYYY'));
      await fetchPipeline(pickerLabel);
      setIsLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="body-holder pipeline-age">
        <Sidebar />
        <div className="section-main">
          <div className="filter-options filter-wrapper">
            <UncontrolledButtonDropdown>
              <DropdownToggle className="filter-head" caret>
                {pickerLabel}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="icon-chevron-down"
                />
              </DropdownToggle>
              <DropdownMenu className="filter-menu">
                {dateSelection.map((date, index) => (
                  <DropdownItem
                    onClick={() => {
                      handleOptionSelect(date);
                    }}
                    key={index}
                  >
                    {date}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>

            <div className="export-container">
              {!isLoading && (
                <button className="export">
                  <CSVLink
                    data={csvData}
                    filename={`Pipeline report ${moment(pickerLabel).format(
                      'YYYY-MM-DD'
                    )}.csv`}
                  >
                    Export as .CSV
                  </CSVLink>
                </button>
              )}
            </div>
          </div>

          {isLoading ? (
            <div className="spinner-container">
              <DotLoader color={'#243f5c'} size={100} />
            </div>
          ) : (
            <div className="content-wrapper">
              <div className="row-wrapper">
                <div></div>
                <div className="header signed-up">
                  <p>Signed up</p>
                </div>
                <div className="header first-plan">
                  <p>Created first plan</p>
                </div>
                <div className="header pre-live">
                  <p>Pre-live</p>
                </div>
                <div className="header free-trial">
                  <p>Free trial</p>
                </div>
                <div className="header paying-customer">
                  <p>Paying customer</p>
                </div>
              </div>
              <div className="row-wrapper">
                <div className="header signed-up">
                  <p>Signed up</p>
                  <p>{pipelineData?.signedUp} customers</p>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="row-wrapper">
                <div className="header first-plan">
                  <p>Created first plan</p>
                  <p>{pipelineData?.createdFirstPlan} customers</p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from1To2?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from1To2?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from1To2?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p className="total-time">Total time</p>
                  <p>Average {pipelineData?.total2}d</p>
                </div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="row-wrapper">
                <div className="header pre-live">
                  <p>Pre-live</p>
                  <p>{pipelineData?.preLive} customers</p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from1To3?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from1To3?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from1To3?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from2To3?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from2To3?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from2To3?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p className="total-time">Total time</p>
                  <p>Average {pipelineData?.total3}d</p>
                </div>
                <div></div>
                <div></div>
              </div>
              <div className="row-wrapper">
                <div className="header free-trial">
                  <p>Free trial</p>
                  <p>{pipelineData?.freeTrial} customers</p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from1To4?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from1To4?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from1To4?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from2To4?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from2To4?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from2To4?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from3To4?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from3To4?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from3To4?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p className="total-time">Total time</p>
                  <p>Average {pipelineData?.total4}d</p>
                </div>
                <div></div>
              </div>
              <div className="row-wrapper">
                <div className="header paying-customer with-desc">
                  <p>Paying customer</p>
                  <p>{pipelineData?.payingCustomer} customers</p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from1To5?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from1To5?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from1To5?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from2To5?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from2To5?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from2To5?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from3To5?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from3To5?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from3To5?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p>Average: {pipelineData?.from4To5?.average ?? 0}d</p>
                  <p>10th%: {pipelineData?.from4To5?.tenthPercentile ?? 0}d</p>
                  <p>
                    90th %: {pipelineData?.from4To5?.ninetiethPercentile ?? 0}d
                  </p>
                </div>
                <div className="data-wrapper">
                  <p className="total-time">Total time</p>
                  <p>Average {pipelineData?.total5}d</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PipelineAge;
