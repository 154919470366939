import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

import {AdBudget} from '../helpers/Urls';
import auth from '../helpers/Auth';

import axios from 'axios';
import moment from 'moment';
import classnames from 'classnames';
import CurrencyInput from 'react-currency-masked-input';
import Auth from '../helpers/Auth';

const UpdateSpendData = () => {
  const history = useHistory();

  const [adBudgets, setAdBudgets] = useState([]);
  const [newAdBudgets, setNewAdBudgets] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [updateToggle, setUpdateToggle] = useState(false);

  const [createBudget, setCreateBudget] = useState({
    ad_budget: 0,
  });

  const fetchAdBudgets = () => {
    axios
      .get(
        AdBudget.get + '?year=' + moment().format('YYYY'),
        Auth.getAuthConfig()
      )
      .then(({data}) => {
        setAdBudgets(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateAdBudgets = (e) => {
    e.preventDefault();

    if (createBudget.ad_budget !== 0) {
      setIsLoading(true);

      axios
        .post(AdBudget.create, createBudget, auth.getAuthConfig())
        .then(({data}) => {
          setIsLoading(false);
          setUpdateToggle(!updateToggle);
          setCreateBudget({
            ...createBudget,
            ad_budget: 0,
          });

          newAdBudgets.length === 0 && history.push('/marketing-conversion');
        })
        .catch((err) => {
          console.log(err);
        });
    }

    newAdBudgets.forEach(async (budget, i) => {
      if (budget.touched) {
        setIsLoading(true);
        axios
          .put(AdBudget.update, budget, auth.getAuthConfig())
          .then(({data}) => {
            setIsLoading(false);
            setUpdateToggle(!updateToggle);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      setTimeout(() => {
        history.push('/marketing-conversion');
      }, 1000);
    });
  };

  const handleCreateData = (e) => {
    if (adBudgets[0].monthint === 12) {
      setCreateBudget({
        month: 1,
        year: moment().add(1, 'year').format('YYYY'),
        ad_budget: parseFloat(e.target.value),
      });
    } else {
      setCreateBudget({
        month: adBudgets[0].monthint + 1,
        year: moment().format('YYYY'),
        ad_budget: parseFloat(e.target.value),
      });
    }
  };

  useEffect(() => {
    fetchAdBudgets();
  }, [updateToggle]);

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="update-spend-data body-holder">
        <Sidebar />
        <div className="section-main">
          <Link to="/marketing-conversion" className="link">
            <span>&#8249;</span> Back to report
          </Link>
          <div className="form-wrapper">
            <form onSubmit={updateAdBudgets}>
              <div className="main-form-holder">
                <div className="tr">
                  <div className="th">Month</div>
                  <div className="th">Ad Budget</div>
                </div>

                {adBudgets.length > 0 && (
                  <div className="tr">
                    <div className="td">
                      {adBudgets[0].monthint + 1 === 13
                        ? moment(1, 'M').format('MMMM') +
                          ' ' +
                          (parseFloat(adBudgets[0].yearstr) + 1)
                        : moment(adBudgets[0].monthint + 1, 'M').format(
                            'MMMM'
                          ) +
                          ' ' +
                          adBudgets[0].yearstr}
                    </div>
                    <div className="td">
                      <div className="input-wrapper">
                        <span>$</span>
                        <input
                          value={createBudget.ad_budget}
                          onChange={handleCreateData}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {adBudgets.map((budget, i) => (
                  <div className="tr" key={i}>
                    <div className="td">
                      {moment(budget.monthint, 'M').format('MMMM')}{' '}
                      {budget.yearstr}
                    </div>
                    <div className="td">
                      <div className="input-wrapper">
                        <span>$</span>
                        <CurrencyInput
                          name=""
                          value={budget.ad_budget}
                          onChange={(e) => {
                            var budgets = adBudgets.slice();
                            budgets[i].ad_budget = parseFloat(e.target.value);
                            budgets[i].touched = 1;
                            setNewAdBudgets(budgets);
                          }}
                          required
                        />
                      </div>
                      {/* <input
                          name=""
                          value={budget.ad_budget}
                          onChange={(e) => {
                            var budgets = adBudgets.slice();
                            budgets[i].ad_budget = parseFloat(e.target.value);
                            budgets[i].touched = 1;
                            setNewAdBudgets(budgets)
                          }}
                        /> */}
                    </div>
                  </div>
                ))}
              </div>
              <button
                type="submit"
                className={classnames('btn btn-orange', {
                  disabled: isLoading === true,
                })}
              >
                <img
                  className="icon-refresh"
                  src={require('../assets/images/refresh.svg')}
                  alt="refresh-report"
                />
                Update Report
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateSpendData;
