import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import NumberFormat from 'react-number-format';

const DateFormatter = (date, seperator, yearRange) => {
  const _date = date.split(' ');
  const month = _date[0].substring(0, 3);
  const year = _date[1].substring(yearRange[0], yearRange[1]);
  return month + seperator + year;
};
const CustomTooltip = (props) => {
  if (props.active) {
    const {label, payload} = props;
    if (payload) {
      return (
        <div className="custom-tooltip">
          <h6>
            {label !== undefined ? DateFormatter(label, ' ', [0, 4]) : ''}
            {/* {moment(label, 'MMMM YYYY', true).format('MMM YYYY').toString()} */}
          </h6>
          <p>{payload[0].payload.multiplier_formatted}</p>
          <p>
            {'$'}
            <NumberFormat
              value={payload[0].payload.invoice_value.toFixed(2)}
              displayType={'text'}
              thousandSeparator={true}
            />
          </p>
        </div>
      );
    }
  }
  return null;
};

const TargetChartView = ({chartData}) => {
  const xAxisTickFormatter = (date) => {
    // return moment(date, 'MMMM YYYY', true).format('MMM`YY').toString();
    return DateFormatter(date, '`', [2, 4]);
  };

  const yAxisTickFormatter = (value) => {
    return `${value}x`;
  };

  return (
    <div className="section-chart chart-target">
      <ResponsiveContainer width="100%" height={540}>
        <LineChart
          data={chartData}
          margin={{top: 5, right: 30, left: 20, bottom: 5}}
        >
          <CartesianGrid
            vertical={false}
            stroke="#252422"
            strokeDasharray="1 1"
          />

          <XAxis
            dataKey="date"
            tickFormatter={xAxisTickFormatter}
            axisLine={false}
            tickLine={false}
            tickMargin={11}
            interval={0}
            stroke="#252422"
          />

          <YAxis
            dataKey="multiplier"
            tickFormatter={yAxisTickFormatter}
            axisLine={false}
            tickLine={false}
            tickMargin={7}
            stroke="#252422"
            type="number"
            domain={['dataMin', 'dataMax']}
            scale="log"
            // interval={1}
            ticks={[1, 25, 50, 100, 200, 400, 8235, 88235, 882345]}
          />

          <Tooltip active={false} content={<CustomTooltip />} />

          <Legend />

          <Line
            type="linear"
            dataKey="multiplier"
            stroke="#FE7F2D"
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TargetChartView;
