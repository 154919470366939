export const calculateScore = (data) => {
  var mrr = 0;
  if (data.scorecard.mrr === '< $10') mrr = 0;
  else if (data.scorecard.mrr === '$10-$100') mrr = 5;
  else if (data.scorecard.mrr === '$100-$1000') mrr = 10;
  else if (data.scorecard.mrr === '$1000+') mrr = 20;
  else mrr = 0;

  var size = 0;
  if (data.scorecard.size === 'Lifestyle') size = 0;
  else if (data.scorecard.size === 'Startup') size = 5;
  else if (data.scorecard.size === 'Scale-up') size = 10;
  else if (data.scorecard.size === 'SME') size = 20;
  else if (data.scorecard.size === 'Inc 5000') size = 10;
  else if (data.scorecard.size === 'Enterprise') size = 5;
  else if (data.scorecard.size === 'Multi-national') size = 5;
  else size = 0;

  var migration = 0;
  if (data.scorecard.migration === '<100 subs') migration = -10;
  else if (data.scorecard.migration === '100-1k subs') migration = 0;
  else if (data.scorecard.migration === '1-10k subs') migration = 5;
  else if (data.scorecard.migration === '10k+ subs') migration = 10;
  else migration = 0;

  var skill = 0;
  if (data.scorecard.skill === 'Low') skill = -10;
  else if (data.scorecard.skill === 'Medium') skill = 0;
  else if (data.scorecard.skill === 'High') skill = 10;

  var xFactor = data.scorecard.xFactor === null ? 0 : data.scorecard.xFactor;

  var totalScore = mrr + size + migration + skill + xFactor;

  return totalScore;
};
