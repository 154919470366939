import React from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBullseye} from '@fortawesome/free-solid-svg-icons';
const Sidebar = () => {
  return (
    <div className="sidebar">
      <ul>
        <h5>Reports</h5>
        <li>
          <NavLink
            exact={true}
            activeClassName="is-active"
            className="sidebar-link"
            to="/"
          >
            <FontAwesomeIcon icon={faBullseye} /> Target $30m
          </NavLink>
          <NavLink
            exact={true}
            activeClassName="is-active"
            className="sidebar-link"
            to="/customer-signups"
          >
            Customer signups
          </NavLink>

          <NavLink
            exact={true}
            activeClassName="is-active"
            className="sidebar-link"
            to="/evaluation-customers"
          >
            Evaluation customers
          </NavLink>
          <NavLink
            exact={true}
            activeClassName="is-active"
            className="sidebar-link"
            to="/in-life-customers"
          >
            In-life customers
          </NavLink>

          <NavLink
            activeClassName="is-active"
            className="sidebar-link"
            to="/invoice-value"
          >
            Invoice value
          </NavLink>

          <NavLink
            activeClassName="is-active"
            className="sidebar-link"
            to="/marketing-conversion"
          >
            Marketing site conversion
          </NavLink>

          <NavLink
            activeClassName="is-active"
            className="sidebar-link"
            to="/customer-voice"
          >
            Customer voice
          </NavLink>

          <NavLink
            activeClassName="is-active"
            className="sidebar-link"
            to="/mrr-movers"
          >
            MRR movers
          </NavLink>

          <NavLink
            activeClassName="is-active"
            className="sidebar-link"
            to="/partner-revenue-share"
          >
            Partner revenue share
          </NavLink>

          <NavLink
            activeClassName="is-active"
            className="sidebar-link"
            to="/pipeline-age"
          >
            Pipeline age
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
