import React, { useState } from 'react';

const NotificationBar = (props) => {

  const [hidden, setHidden] = useState(false)

  const hideNotifBar = () => {
    setHidden(true)
    if (typeof window !== `undefined`) {
      localStorage.setItem('visited', true)
    }
  }

  return (
    // <div className={classnames('notification-bar', {
    //   'hide': hidden
    // })}>
    //   <div className="container">
    //     <p className="notification-message"> {props.message} </p>
    //     <button onClick={hideNotifBar} className="close">&times;</button>
    //   </div>
    // </div >
    <>
      {
        !hidden && <div className="notification-bar">
          <div className="container">
            <p className="notification-message"> {props.message} </p>
            <button onClick={hideNotifBar} className="close">&times;</button>
          </div>
        </div >
      }
    </>
  )
}

export default NotificationBar;