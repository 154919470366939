import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ReportTable from '../components/ReportTable';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import axios from 'axios';
import {partnersRevenueShare} from '../helpers/Urls';
import DotLoader from 'react-spinners/DotLoader';

import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import Auth from '../helpers/Auth';

const PartnerRevenueShare = () => {
  const [pickerLabel, setPickerLabel] = useState(
    moment().subtract(1, 'months').format('MMMM YYYY')
  );
  const [dateSelection, setDateSelection] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const tableHeaders = [
    {title: 'Partner'},
    {title: 'Revenue Share'},
    {title: 'Report'},
  ];

  const getPartners = (q) => {
    setIsLoading(true);
    return axios
      .get(partnersRevenueShare.allPartners + '?q=' + q, Auth.getAuthConfig())
      .then(({data}) => {
        setPartnerList(data?.partners ?? []);
        localStorage.setItem(
          'pdfData',
          JSON.stringify(data?.partners[0].pdf_data ?? [])
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('something went wrong');
      });
  };
  const getReportDates = () => {
    return axios
      .get(partnersRevenueShare.reportDates, Auth.getAuthConfig())
      .then(({data}) => {
        let dates = data.map((date) => date.month_reported);
        console.log('dates', dates);
        setDateSelection(dates);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('something went wrong fetching dates');
      });
  };

  const handleSelectDateDropdown = (date) => {
    setPickerLabel(date);
    getPartners(date);
  };

  useEffect(() => {
    getReportDates();
    getPartners(moment().subtract(1, 'months').format('MMMM YYYY'));
  }, []);

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="filter-options filter-wrapper">
            <UncontrolledButtonDropdown>
              <DropdownToggle className="filter-head" caret>
                {pickerLabel}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="icon-chevron-down"
                />
              </DropdownToggle>
              <DropdownMenu className="filter-menu">
                {dateSelection.map((date, index) => (
                  <DropdownItem
                    onClick={() => {
                      handleSelectDateDropdown(date);
                    }}
                    key={index}
                  >
                    {date}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>

          {isLoading ? (
            <div className="spinner-container">
              <DotLoader color={'#243f5c'} size={100} />
            </div>
          ) : (
            <ReportTable
              statistics={partnerList}
              tableHeaders={tableHeaders}
              chartType="partnerReport"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PartnerRevenueShare;
