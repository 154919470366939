import React, {useContext, useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import moment from 'moment';

import Auth from '../helpers/Auth';
import {Context} from '../contexts/Context';
import {FailedPayment} from '../helpers/Urls';

const Header = (props) => {
  const {dateUpdated} = useContext(Context);
  const [currentDate] = useState(() => {
    if (!dateUpdated) {
      const lastUpdate = localStorage.getItem('billsby-last-update');
      if (lastUpdate) {
        return moment(lastUpdate).add(1, 'days').format('MMMM D YYYY');
      }
    } else {
      return moment(dateUpdated).add(1, 'days').format('MMMM D YYYY');
    }
  });
  const [utc] = useState(() => {
    if (!dateUpdated) {
      const lastUpdate = localStorage.getItem('billsby-last-update');
      if (lastUpdate) {
        return moment.utc(lastUpdate).format('HH:mm');
      }
    } else {
      return moment.utc(dateUpdated).format('HH:mm');
    }
  });

  const [availableDataRange, setAvailableDataRange] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (props.path !== undefined) {
      if (props.path === '/failed-payment-lookup') {
        axios
          .get(FailedPayment.dateRange, Auth.getAuthConfig())
          .then(({data}) => {
            setAvailableDataRange(data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    //eslint-disable-next-line
  }, []);

  return (
    <header>
      <div className="container container-flex">
        <div className="logo-wrapper">
          <Link className="nav-logo" to="/">
            <img
              className="logo-orig"
              src={require('../assets/images/Logo.png')}
              srcSet={require('../assets/images/Logo@2x.png')}
              alt="Logo"
            />
            Business Insights
          </Link>

          {props.isLoggedIn && props.path === undefined && (
            <p className="latest-update">
              Last updated {currentDate} at {utc} UTC
            </p>
          )}
          {props.isLoggedIn &&
            props.path !== undefined &&
            props.path === '/failed-payment-lookup' && (
              <p className="latest-update">
                Data available from{' '}
                {moment(availableDataRange.from).format('DD MMM hh:mm')} to{' '}
                {moment(availableDataRange.to).format('DD MMM hh:mm')} - Updates
                every five minutes
              </p>
            )}
        </div>
        {props.isLoggedIn && (
          <button onClick={Auth.logout} className="btn-white">
            Logout
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
