import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import global from '../helpers/Global';

const CustomTooltip = (props) => {
  if (props.active) {
    const {label, payload} = props;
    return (
      <div className="custom-tooltip">
        <h6>{moment(label).format('MMM D YYYY')}</h6>
        <p>
          <NumberFormat
            value={global.marketingNullChecker(payload[0].value)}
            displayType={'text'}
            thousandSeparator={true}
          />{' '}
          unique visitors
        </p>
        <p>
          <NumberFormat
            value={global.marketingNullChecker(payload[0].payload.sign_ups)}
            displayType={'text'}
            thousandSeparator={true}
          />{' '}
          sign ups
        </p>
        <p>
          <NumberFormat
            value={global.marketingNullChecker(
              payload[0].payload.conversion_rate * 100
            )}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            suffix="%"
          />{' '}
          conversion
        </p>

        <br />

        <p>
          <NumberFormat
            value={global.marketingNullChecker(payload[0].payload.ad_budget)}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="$"
          />{' '}
          ad budget
        </p>
        <p>
          <NumberFormat
            value={global.marketingNullChecker(
              payload[0].payload.cost_per_conversion
            )}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="$"
          />{' '}
          CPC
        </p>
      </div>
    );
  }

  return null;
};

const MarketingConversionChart = ({chartData}) => {
  const xAxisTickFormatter = (date) => {
    return moment(date).format('MMM D');
  };

  const yAxisTickFormatter = (cost_per_conversion) => {
    return '$' + cost_per_conversion;
  };

  return (
    <div className="section-chart chart-marketing">
      <ResponsiveContainer width="100%" height={540}>
        <ComposedChart
          data={chartData}
          width={100}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid
            vertical={false}
            stroke="#252422"
            strokeDasharray="1 1"
          />

          <XAxis
            dataKey="date"
            tickFormatter={xAxisTickFormatter}
            axisLine={false}
            tickLine={false}
            tickMargin={11}
            // interval={13}
            stroke="#252422"
            padding={{left: 0, right: 0}}
          />

          <YAxis
            yAxisId="left"
            axisLine={false}
            tickLine={false}
            tickMargin={7}
            stroke="#252422"
            type="number"
            tickCount={9}
          />

          <YAxis
            yAxisId="right"
            orientation="right"
            axisLine={false}
            tickFormatter={yAxisTickFormatter}
            tickLine={false}
            tickMargin={7}
            stroke="#252422"
            type="number"
            tickCount={9}
          />

          <Tooltip active={false} content={<CustomTooltip />} />

          <Legend />

          <Line
            yAxisId="left"
            dataKey="uniques"
            stroke="#fe7f2d"
            strokeWidth={2}
          />

          <Bar
            yAxisId="right"
            dataKey="cost_per_conversion"
            barSize={20}
            fill="#243f5c"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MarketingConversionChart;
