import React, {useEffect, useState} from 'react';
import axios from 'axios';
import DotLoader from 'react-spinners/DotLoader';

import {Invoice} from '../helpers/Urls';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import DataCard from '../components/DataCard';

import InvoiceValueChart from '../components/InvoiceValueChart.js';
import ReportTable from '../components/ReportTable';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Auth from '../helpers/Auth';

const InvoiceValue = () => {
  const [startDate, setStartDate] = useState(() => {
    return moment().subtract(30, 'days');
  });
  const [endDate, setEndDate] = useState(() => {
    return moment().subtract(1, 'days');
  });

  const [pickerLabel, setPickerLabel] = useState(() => {
    return 'Last 30 days';
  });
  const [isLoading, setIsLoading] = useState(false);

  const tableHeaders = [
    {title: 'Date'},
    {title: 'Total Paid Invoices'},
    {title: 'Invoice Value'},
    {title: 'Revenue Share'},
    // {title: 'Invoice Value', subtitle: '(excl. free trial)'},
    // {title: 'Revenue Share', subtitle: '(excl. free trial)'},
  ];

  const ranges = {
    'Last 7 days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
    'Last 30 days': [
      moment().subtract(30, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 60 days': [
      moment().subtract(60, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 90 days': [
      moment().subtract(90, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 180 days': [
      moment().subtract(180, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last 365 days': [
      moment().subtract(365, 'days'),
      moment().subtract(1, 'days'),
    ],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
  };

  // const computeDateRange = (date) => {
  //   var start = moment(date.start),
  //     end = moment(date.end);
  //   if (
  //     start.format('MMM DD YYYY') ===
  //       moment().subtract(7, 'days').format('MMM DD YYYY') &&
  //     end.format('MMM DD YYYY') ===
  //       moment().subtract(1, 'days').format('MMM DD YYYY')
  //   ) {
  //     return 'Last 7 days';
  //   } else if (
  //     start.format('MMM DD YYYY') ===
  //       moment().subtract(30, 'days').format('MMM DD YYYY') &&
  //     end.format('MMM DD YYYY') ===
  //       moment().subtract(1, 'days').format('MMM DD YYYY')
  //   ) {
  //     return 'Last 30 days';
  //   } else if (
  //     start.format('MMM DD YYYY') ===
  //       moment().subtract(60, 'days').format('MMM DD YYYY') &&
  //     end.format('MMM DD YYYY') ===
  //       moment().subtract(1, 'days').format('MMM DD YYYY')
  //   ) {
  //     return 'Last 60 days';
  //   } else if (
  //     start.format('MMM DD YYYY') ===
  //       moment().subtract(90, 'days').format('MMM DD YYYY') &&
  //     end.format('MMM DD YYYY') ===
  //       moment().subtract(1, 'days').format('MMM DD YYYY')
  //   ) {
  //     return 'Last 90 days';
  //   } else if (
  //     start.format('MMM DD YYYY') ===
  //       moment().subtract(180, 'days').format('MMM DD YYYY') &&
  //     end.format('MMM DD YYYY') ===
  //       moment().subtract(1, 'days').format('MMM DD YYYY')
  //   ) {
  //     return 'Last 180 days';
  //   } else if (
  //     start.format('MMM DD YYYY') ===
  //       moment().subtract(365, 'days').format('MMM DD YYYY') &&
  //     end.format('MMM DD YYYY') ===
  //       moment().subtract(1, 'days').format('MMM DD YYYY')
  //   ) {
  //     return 'Last 365 days';
  //   } else if (
  //     start.format('MMM DD YYYY') ===
  //       moment().subtract(1, 'year').startOf('year').format('MMM DD YYYY') &&
  //     end.format('MMM DD YYYY') === moment().subtract(1, 'year').endOf('year')
  //   ) {
  //     return 'Last Year';
  //   } else {
  //     return start.format('MMM DD YYYY') + ' - ' + end.format('MMM DD YYYY');
  //   }
  // };

  const handleDateRangeEvent = (e, picker) => {
    var start = picker.startDate,
      end = picker.endDate;

    setStartDate(start);
    setEndDate(end);

    if (
      start.format('MMM DD YYYY') ===
        moment().subtract(7, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 7 days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(30, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 30 days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(60, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 60 days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(90, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 90 days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(180, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 180 days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(365, 'days').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') ===
        moment().subtract(1, 'days').format('MMM DD YYYY')
    ) {
      setPickerLabel('Last 365 days');
    } else if (
      start.format('MMM DD YYYY') ===
        moment().subtract(1, 'year').startOf('year').format('MMM DD YYYY') &&
      end.format('MMM DD YYYY') === moment().subtract(1, 'year').endOf('year')
    ) {
      setPickerLabel('Last Year');
    } else {
      setPickerLabel(
        start.format('MMM DD YYYY') + ' - ' + end.format('MMM DD YYYY')
      );
    }

    fetchInvoiceFromDate(start, end);
  };

  const [data, setData] = useState([]);
  const [totalPaidInvoice, setTotalPaidInvoice] = useState(0);
  const [totalInvoiceValue, setTotalInvoiceValue] = useState(0);

  const [totalRevenueShare, setTotalRevenueShare] = useState(0);

  const loadData = (data) => {
    setIsLoading(false);

    setData(data);
    var total_paid_invoice = 0;
    var total_invoice_value = 0;
    var total_revenue_share = 0;
    data.forEach((invoice) => {
      total_paid_invoice += invoice.totalPaidInvoices;
      total_invoice_value += invoice.totalInvoiceAmount;
      total_revenue_share += invoice.revenueShare;
    });
    setTotalPaidInvoice(total_paid_invoice);
    setTotalInvoiceValue(total_invoice_value);
    setTotalRevenueShare(total_revenue_share);
  };

  const fetchInvoiceFromDate = (start, end) => {
    setIsLoading(true);
    axios
      .post(
        Invoice.getInvoiceFromDate,
        {
          start,
          end,
        },
        Auth.getAuthConfig()
      )
      .then(({data}) => {
        loadData(data);
      });
  };

  useEffect(() => {
    fetchInvoiceFromDate(startDate, endDate);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Header isLoggedIn={true} />
      <div className="invoice-value body-holder">
        <Sidebar />
        <div className="section-main">
          <div className="filter-options">
            <DateRangePicker
              onApply={handleDateRangeEvent}
              initialSettings={{
                startDate: startDate,
                endDate: endDate,
                ranges: ranges,
                maxDate: moment().subtract(1, 'days'),
                alwaysShowCalendars: true,
              }}
            >
              <button className="filter-head dropdown-toggle btn btn-secondary">
                {pickerLabel}
              </button>
            </DateRangePicker>
          </div>

          {isLoading ? (
            <div className="spinner-container">
              <DotLoader color={'#243f5c'} size={100} />
            </div>
          ) : (
            <>
              <InvoiceValueChart chartData={data} />

              <div className="data-card-holder iv-data-card-holder">
                <DataCard
                  heading="Total Paid Invoices"
                  dataValue={
                    <NumberFormat
                      value={totalPaidInvoice}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                  }
                />
                <DataCard
                  heading="Invoice Value"
                  dataValue={
                    <NumberFormat
                      value={totalInvoiceValue}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix="$"
                    />
                  }
                />
                <DataCard
                  heading="Revenue Share"
                  subheading=""
                  dataValue={
                    <NumberFormat
                      value={totalRevenueShare}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix="$"
                    />
                  }
                />
                {/* <DataCard
              heading="Invoice Value"
              subheading="(excl. free trial)"
              dataValue={
                <NumberFormat
                  value={totalInvoiceValueExclFreeTrial}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="$"
                />
              }
            />
            <DataCard
              heading="Revenue Share"
              subheading="(excl. free trial)"
              dataValue={
                <NumberFormat
                  value={totalRevenueShareExcl}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="$"
                />
              }
            /> */}
              </div>

              <ReportTable
                statistics={data}
                tableHeaders={tableHeaders}
                chartType="invoiceValue"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceValue;
